// Dot that appears on an incident log item if it is unread
// When viewing the row, the incident will be marked as read

import { useEffect } from "react"
import { useParams } from "react-router-dom"

import useMarkIncidentAsReadMutation from "./mutations/useMarkIncidentAsReadMutation"
import NotificationDot from "../../../components/notificationDot/NotificationDot"
import { useIncidentsQuery } from "../../../networking/queries"

const IncidentLogItemUnreadDot = ({
  id,
  isTableVisible,
}: {
  id: number
  isTableVisible: boolean
}) => {
  const { data: incidents } = useIncidentsQuery()
  const { userId } = useParams()
  const isExamplePatient = Number(userId) === 0
  const incident = incidents?.find((incident) => incident.id === id)
  const markIncidentAsReadMutation = useMarkIncidentAsReadMutation()

  useEffect(() => {
    if (isExamplePatient) return
    if (!incident) return
    if (incident?.date_read) return
    if (!isTableVisible) return
    setTimeout(() => {
      markIncidentAsReadMutation.mutate(incident.id)
    }, 2500)
  }, [incident, markIncidentAsReadMutation, isTableVisible, isExamplePatient])

  if (incident?.date_read) return null

  return <NotificationDot />
}

export default IncidentLogItemUnreadDot
