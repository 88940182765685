import {
  Button,
  FlexBox,
  ParentTraining,
  ParentTrainingIcon,
  ParentTrainingStatus,
  SegmentedControl,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useMemo } from "react"

import AddEditParentTrainingModal from "./AddEditParentTrainingModal"
import ParentTrainingItem from "./ParentTrainingItem"
import TrainingsEmptyState from "./TrainingsEmptyState"
import { useAddEditParentTrainingStore } from "./useAddEditParentTrainingStore"
import useParentTasks from "./useParentTraining"
import useParentTraining from "./useParentTraining"
import useParentTrainingStore from "./useParentTrainingStore"
import { QuickActionMobileButtonGroup } from "../../../components/buttons/QuickActionMobileButton"
import PageContentWrapper from "../../../components/layout/PageContentWrapper"
import { SkeletonLoaderRow } from "../../../components/loading/SkeletonLoader"
import {
  useParentTrainingsQuery,
  usePatientParentsQuery,
} from "../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

const hasNoneOrDefaultTraining = (
  parentTrainings: ParentTraining[] | undefined
) => {
  if (!parentTrainings || parentTrainings.length === 0) return true
  if (parentTrainings.length === 1)
    return parentTrainings[0].title.includes("Getting Started with Joon")
  return false
}

const ParentTrainingsPage = () => {
  const { onOpen: openAddParentTraining } = useAddEditParentTrainingStore()

  const isMobile = useMediaQuery("(max-width: 800px)")

  useParentTasks()
  useParentTraining()

  return (
    <PageContentWrapper>
      <FlexBox direction="column" gap={SPACING.space2}>
        <FlexBox
          direction="row"
          gap={SPACING.space2}
          justify="space-between"
          align="center"
          wrap={false}
        >
          <FlexBox
            direction="row"
            gap={SPACING.space2}
            align="center"
            style={{ width: "fit-content" }}
          >
            <ParentTrainingIcon />
            <Typography variant="h3">Parent Trainings</Typography>
          </FlexBox>
          {!isMobile && (
            <Button
              text="+ Add training"
              style={{ whiteSpace: "nowrap" }}
              onClick={() => {
                openAddParentTraining()
                trackAnalyticEvent(ANALYTIC_EVENTS.open_add_parent_training)
              }}
            />
          )}
        </FlexBox>
        <ParentToggleList />
      </FlexBox>
      <ParentTrainingsList />
      <QuickActionMobileButtonGroup
        buttons={[
          {
            text: "Add training",
            onClick: () => {
              openAddParentTraining()
              trackAnalyticEvent(ANALYTIC_EVENTS.open_add_parent_training)
            },
          },
        ]}
      />
      <AddEditParentTrainingModal />
    </PageContentWrapper>
  )
}

export default ParentTrainingsPage

const ParentTrainingsList = () => {
  const { data: parentTrainings, isLoading: isLoadingTrainings } =
    useParentTrainingsQuery()

  // group trainings by status and add a header for each group
  const groupedTrainings = useMemo(() => {
    const groups = {
      [ParentTrainingStatus.INACTIVE]: {
        label: "Drafts",
        trainings: [],
      },
      [ParentTrainingStatus.IN_PROGRESS]: {
        label: "Active trainings",
        trainings: [],
      },
      [ParentTrainingStatus.MASTERED]: {
        label: "Mastered",
        trainings: [],
      },
    } as Record<
      ParentTrainingStatus,
      { label: string; trainings: ParentTraining[] }
    >
    parentTrainings?.forEach((training) => {
      groups[training.status].trainings.push(training)
    })
    return groups
  }, [parentTrainings])

  if (isLoadingTrainings) return <SkeletonLoaderRow numRows={5} />

  const displayEmptyState = hasNoneOrDefaultTraining(parentTrainings)

  return (
    <FlexBox direction="column" gap={SPACING.space6}>
      {Object.values(groupedTrainings).map((group) => {
        if (group.trainings.length === 0) return null
        return (
          <FlexBox direction="column" gap={SPACING.space2} key={group.label}>
            <Typography variant="caption">{group.label}</Typography>
            {group.trainings.map((training) => (
              <ParentTrainingItem key={training.id} parentTraining={training} />
            ))}
          </FlexBox>
        )
      })}
      {displayEmptyState && <TrainingsEmptyState />}
    </FlexBox>
  )
}

const ParentToggleList = () => {
  const { data: parents } = usePatientParentsQuery()
  const { selectedParentId, setSelectedParentId } = useParentTrainingStore()

  const parentControlOptions = parents?.map((parent) => ({
    label: parent.user.name,
    value: parent.user.id,
  })) as { label: string; value: number }[]

  if (!parents) return null

  return (
    <FlexBox
      direction="row"
      gap={SPACING.space2}
      wrap={false}
      style={{ width: "fit-content" }}
    >
      <SegmentedControl
        options={parentControlOptions}
        value={selectedParentId}
        setValue={(value) => setSelectedParentId(value as number)}
      />
    </FlexBox>
  )
}
