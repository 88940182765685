const PDFPreview = ({ pdfUrl, previewUrl }: any) => {
  return (
    <a
      href={pdfUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="pdf-link"
    >
      <img className="pdf-link__preview" src={previewUrl} alt="PDF Preview" />
      <div className="pdf-link__overlay">
        <div className="pdf-link__button">View PDF</div>
      </div>
    </a>
  )
}

export default PDFPreview
