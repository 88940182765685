import {
  FlexBox,
  JoonUIColor,
  LearningModuleAssignment,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useNavigate } from "react-router-dom"

import useDataTasksQuery from "./dataTasks/useDataTasksQuery"
import { AddModuleButton } from "./modules/ModulesTab"
import ObservationalDataSection from "./observationalData/ObservationalDataSection"
import PageContentWrapper from "../../../components/layout/PageContentWrapper"
import NotificationDot from "../../../components/notificationDot/NotificationDot"
import {
  useCurrentTrainingQuery,
  useModulesToReviewQuery,
} from "../../../networking/queries"
import { ParentTrainingTab } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

const TrainingSummaryTab = () => {
  const { data: currentTraining } = useCurrentTrainingQuery()

  const isMobile = useMediaQuery("(max-width: 800px)")

  if (!currentTraining) return <></>

  return (
    <PageContentWrapper scrollable={false}>
      <FlexBox
        direction={isMobile ? "column" : "row"}
        justify="space-between"
        gap={SPACING.space6}
        wrap={false}
      >
        <FlexBox direction="column" gap={SPACING.space2} align="unset">
          <Typography variant="bodyBold">Learning Modules</Typography>
          <ModuleSummarySection />
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space2} align="unset">
          <Typography variant="bodyBold">Tasks</Typography>
          <DataTaskSummarySection />
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space2} align="unset">
          <Typography variant="bodyBold">Quick to-dos</Typography>
          <QuickToDoSection />
        </FlexBox>
      </FlexBox>
      <ObservationalDataSection />
    </PageContentWrapper>
  )
}

const ModuleSummarySection = () => {
  const { data: currentTraining } = useCurrentTrainingQuery()
  const { data: numberOfModulesToReview } = useModulesToReviewQuery()

  const navigate = useNavigate()

  const remainingModules = getRemainingModules(currentTraining?.assignments)

  const onClickReviewModules = () => {
    const currentPath = window.location.pathname
    const newPath = currentPath.replace(
      ParentTrainingTab.SUMMARY,
      ParentTrainingTab.MODULES
    )
    navigate(newPath)
    trackAnalyticEvent(ANALYTIC_EVENTS.view_modules_tab)
  }

  if (!currentTraining) return <></>

  if (currentTraining.assignments.length === 0) return <AddModuleButton />

  return (
    <FlexBox
      direction="row"
      gap={SPACING.space2}
      align="center"
      justify="space-between"
      style={{
        border: `1px solid ${JoonUIColor.border.default}`,
        padding: SPACING.space2,
        paddingLeft: SPACING.space4,
        borderRadius: SPACING.space2,
        background: JoonUIColor.background.primaryNeutral,
      }}
    >
      {remainingModules.length > 0 ? (
        <>
          <Typography variant="caption">
            {remainingModules.length} remaining
          </Typography>
          <TextButton onClick={onClickReviewModules}>
            <Typography
              variant="caption"
              color={JoonUIColor.text.primaryAccent}
            >
              View
            </Typography>
          </TextButton>
        </>
      ) : !!numberOfModulesToReview ? (
        <>
          <span>
            <NotificationDot />
            <Typography
              variant="caption"
              style={{ marginLeft: SPACING.space2 }}
            >
              {numberOfModulesToReview} ready to review
            </Typography>
          </span>
          <TextButton onClick={onClickReviewModules}>
            <Typography
              variant="caption"
              color={JoonUIColor.text.primaryAccent}
            >
              Review
            </Typography>
          </TextButton>
        </>
      ) : currentTraining.assignments.length === 0 ? (
        <Typography variant="caption">No modules assigned</Typography>
      ) : (
        <>
          <Typography variant="caption">
            {currentTraining.assignments.length} assigned modules
          </Typography>
          <TextButton onClick={onClickReviewModules}>
            <Typography
              variant="caption"
              color={JoonUIColor.text.primaryAccent}
            >
              View
            </Typography>
          </TextButton>
        </>
      )}
    </FlexBox>
  )
}

const QuickToDoSection = () => {
  const { data: currentTraining } = useCurrentTrainingQuery()
  const tasks = currentTraining?.quests || []
  const navigate = useNavigate()

  const onClickViewTasks = () => {
    const currentPath = window.location.pathname
    const newPath = currentPath.replace(
      ParentTrainingTab.SUMMARY,
      ParentTrainingTab.TODO_TASKS
    )
    navigate(newPath)
    trackAnalyticEvent(ANALYTIC_EVENTS.view_tasks_tab)
  }

  return (
    <FlexBox
      direction="row"
      gap={SPACING.space2}
      align="center"
      justify="space-between"
      style={{
        border: `1px solid ${JoonUIColor.border.default}`,
        padding: SPACING.space2,
        paddingLeft: SPACING.space4,
        borderRadius: SPACING.space2,
        background: JoonUIColor.background.primaryNeutral,
      }}
    >
      <Typography variant="caption">
        {tasks.length} active task{tasks.length > 1 ? "s" : ""}
      </Typography>
      <TextButton onClick={onClickViewTasks}>
        <Typography variant="caption" color={JoonUIColor.text.primaryAccent}>
          View
        </Typography>
      </TextButton>
    </FlexBox>
  )
}

const DataTaskSummarySection = () => {
  const navigate = useNavigate()
  const { data: dataTasks } = useDataTasksQuery()

  const onClickViewTasks = () => {
    const currentPath = window.location.pathname
    const newPath = currentPath.replace(
      ParentTrainingTab.SUMMARY,
      ParentTrainingTab.DATA_TASKS
    )
    navigate(newPath)
  }

  return (
    <FlexBox
      direction="row"
      gap={SPACING.space2}
      align="center"
      justify="space-between"
      style={{
        border: `1px solid ${JoonUIColor.border.default}`,
        padding: SPACING.space2,
        paddingLeft: SPACING.space4,
        borderRadius: SPACING.space2,
        background: JoonUIColor.background.primaryNeutral,
      }}
    >
      <Typography variant="caption">
        {dataTasks?.length} practice tasks
      </Typography>
      <TextButton onClick={onClickViewTasks}>
        <Typography variant="caption" color={JoonUIColor.text.primaryAccent}>
          View
        </Typography>
      </TextButton>
    </FlexBox>
  )
}

const getRemainingModules = (assignments: LearningModuleAssignment[] = []) =>
  assignments.filter(
    (assignment) => !assignment.assignment_completion.date_completed
  )

export default TrainingSummaryTab
