import AddPatientModal from "./AddPatientModal"
import BAAModal from "./BAAModal"
import DatePickerModal from "./DatePickerModal"
import ExampleBackstoryModal from "./ExampleBackstoryModal"
import { InvitationAcceptModal } from "./InvitationAcceptModal"
import OnboardModal from "./OnboardModal"
import ReleaseNotesModal from "./ReleaseNotesModal"
import GetStartedModal from "../../pages/getStarted/GetStartedModal"
import { WebinarModal } from "../../pages/patientsDashboard/Webinar"
import OnboardTutorialModal from "../onboardTutorialModal/OnboardTutorialModal"
import UpgradePlanModal from "../paywall/UpgradePlanModal"

const ModalProvider = () => {
  return (
    <>
      <ReleaseNotesModal />
      <OnboardModal />
      <DatePickerModal />
      <WebinarModal />
      <GetStartedModal />
      <InvitationAcceptModal />
      <AddPatientModal />
      <OnboardTutorialModal />
      <ExampleBackstoryModal />
      <BAAModal />
      <UpgradePlanModal />
    </>
  )
}

export default ModalProvider
