import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Shadow,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import AddEditDataTaskModal from "./AddEditDataTaskModal"
import DataTaskItem from "./DataTaskItem"
import DeleteDataTaskModal from "./DeleteDataTaskModal"
import { useAddEditDataTaskStore } from "./useAddEditDataTaskStore"
import useDataTasksQuery from "./useDataTasksQuery"
import PageContentWrapper from "../../../../components/layout/PageContentWrapper"
import { SkeletonLoaderRow } from "../../../../components/loading/SkeletonLoader"
import PageWrapper from "../../../../components/pageWrapper/PageWrapper"

const DataTasksTab = () => {
  const { data: dataTasks, isLoading } = useDataTasksQuery()
  const { onOpen: onOpenAddDataTask } = useAddEditDataTaskStore()

  return (
    <PageWrapper>
      <PageContentWrapper>
        <FlexBox direction="column" wrap={false} gap={SPACING.space2}>
          {isLoading ? (
            <SkeletonLoaderRow numRows={3} />
          ) : dataTasks && dataTasks.length > 0 ? (
            <>
              <FlexBox
                direction="row"
                justify="space-between"
                wrap={false}
                align="center"
              >
                <Typography variant="bodyBold">
                  {dataTasks.length} practice task
                  {dataTasks.length > 1 ? "s" : ""}
                </Typography>
                <TextButton
                  style={{ marginLeft: "auto", whiteSpace: "nowrap" }}
                  onClick={() => onOpenAddDataTask()}
                >
                  <Typography
                    variant="bodyBold"
                    color={JoonUIColor.text.primaryAccent}
                  >
                    + Add task
                  </Typography>
                </TextButton>
              </FlexBox>
              <FlexBox direction="column" gap={SPACING.space2}>
                {dataTasks?.map((task) => (
                  <DataTaskItem key={task.id} task={task} />
                ))}
              </FlexBox>
            </>
          ) : (
            <DataTasksEmptyState />
          )}
        </FlexBox>
      </PageContentWrapper>
      <AddEditDataTaskModal />
      <DeleteDataTaskModal />
    </PageWrapper>
  )
}

const DataTasksEmptyState = () => {
  const { onOpen: onOpenAddDataTask } = useAddEditDataTaskStore()
  const [isHovered, setIsHovered] = useState(false)

  const onClickAdd = () => {
    onOpenAddDataTask()
  }

  return (
    <FlexBox direction="column" gap={SPACING.space2}>
      <Typography variant="bodyBold">
        No parent implementation tasks assigned yet!
      </Typography>
      <button
        style={{ padding: 0, width: "100%" }}
        onClick={onClickAdd}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <FlexBox
          direction="column"
          gap={SPACING.space2}
          style={{
            padding: SPACING.space4,
            border: `1px solid ${JoonUIColor.border.default}`,
            borderRadius: SPACING.space2,
            boxShadow: Shadow.high,
            background: JoonUIColor.background.primaryNeutral,
            outline: isHovered
              ? `2px solid ${JoonColorExpanded.indigo300}`
              : "none",
          }}
        >
          <FlexBox align="center" gap={SPACING.space2} wrap={false}>
            <Typography variant="bodyBold" color={JoonUIColor.semantic.primary}>
              +
            </Typography>
            <Typography variant="bodyBold" textAlign="left">
              Add tasks to get started
            </Typography>
          </FlexBox>
          <Typography variant="bodySmall" textAlign="left">
            <Typography variant="caption">Best for: </Typography>
            Practicing key learnings and building a new habit at home. You can
            set a Goal and ask parents to track progress towards the goal as
            well.
          </Typography>
        </FlexBox>
      </button>
    </FlexBox>
  )
}

export default DataTasksTab
