import {
  GroupType,
  ParentTrainingMasteryProgress,
  QuestDifficulty,
  QuestRoutine,
  QuestStatus,
  User,
  UserRole,
} from "@joonapp/web-shared"
import { Dayjs } from "dayjs"

import { IncidentLogDataType } from "./incidents"
export * from "./incidents"

export interface ParentDataInterface {
  name: string
  nickname: string
  avatar: string | null
  parentQuestionnaireMapping: QuestionAnswerMappingInterface | null
  childrenData: ChildDataInterface[]
}

export interface QuestionAnswerMappingInterface {
  [key: number]: number
}

export interface ChildDataInterface {
  birthdate: number | null
  first_name: string
  questionAnswerMapping: QuestionAnswerMappingInterface
}

export interface ChildAPIResponse {
  id: number
  parent: number
  first_name: string
  gender: string
  birthdate: number | null
  date_created: number
  coins: number
  active_doter: any
  active_doter_type: string
  first_doter_slot: any
  second_doter_slot: any
  third_doter_slot: any
  username: string
}

export interface AddChildRequest {
  first_name: string
  gender: string | null
  birthdate: number | null
}

export interface EditChildRequest extends AddChildRequest {
  id: number
}

export interface AnswerInterface {
  id: number
  answer: string
  value: number
}

export interface QuestionInterface {
  id: number
  question: string
  answers: AnswerInterface[]
}

export interface AlertInterface {
  message: string
  type: "success" | "error"
}

export interface SubscriberAPIResponse {
  activePlan: {
    planType: string
    isActive: boolean
  }
  planIsActive: boolean
  request_date: string
  request_date_ms: number
  subscriber: Subscriber
}

export interface Subscriber {
  entitlements: {
    premium: {
      expires_date: string
      grace_period_expires_date?: string
      product_identifier: string
      purchase_date: string
    }
  }
  first_seen: string
  last_seen: string
  management_url?: string | null
  non_subscriptions?: any
  original_app_user_id: string
  original_application_version: string
  original_purchase_date: string
  other_purchases: any
  subscriptions: {
    [key: string]: any
  }
}

export interface UserInterface {
  avatar: string | null
  date_joined: number
  email: string
  id: string
  last_name: string | null
  family_id: number
  nickname: string
  name: string
  max_free_verified: number
  num_quests_verified: number
}

export interface AuthContextInterface {
  signout: () => void
}

export interface CustomErrorInterface extends Error {
  code?: string
}

export interface MessageInterface {
  name: string
  email: string
  message: string
}

export interface FormInfoFields {
  email: string
  password: string
  firstName: string
  lastName: string
  profession: string
  clinicName: string
  numClinicians: NumberOfClinicians | ""
  isOwner: boolean
}

type FormFieldKey = keyof FormInfoFields

export interface OnboardingStore {
  step: number
  submitting: boolean
  hasClinic: boolean | null
  clinicCode: string
  formInfo: FormInfoFields
  formErrors: { [x: number]: string }
  setFormErrors: (step: number, error: string) => void
  setClinicCode: (code: string) => void
  setSubmitting: (val: boolean) => void
  setHasClinic: (val: boolean | null) => void
  nextStep: () => void
  previousStep: () => void
  setStep: (step: number) => void
  setFormInfo: (fieldName: FormFieldKey, value: string | boolean | null) => void
}

export interface FamilyMemberProfile {
  nickname: string | null
  role: string
  user: {
    id: number
    email: string | null
    last_name: string | null
    legacy_child_id?: number | null
    legacy_parent_id?: number | null
    name: string
    active_doter: {
      type: string
    }
  }
}

export interface DateRange {
  startDate: Dayjs | null
  endDate: Dayjs | null
  label: string
  value: string
}

export interface OneOffCompletedInstance {
  status: QuestStatus
  completion_notes: string
  completion_photo_url: string
  reward: number
  date: string
  verification_notes: string
}

export interface OneOffQuest {
  id: number
  title: string
  difficulty: QuestDifficulty
  routine: QuestRoutine
  deleted: boolean
  start_date: string
  date_updated: string
  redeemable_reward: number
  skill: string | null
  assigner_profile: {
    user_id: number
    nickname: string
    role: UserRole
  }
  completed_instance: OneOffCompletedInstance | null
}

// can add to shared
export interface Invitation {
  id: number
  invitee_email: string
  date_created: string
  inviter: {
    id: number
    name: string
    email: string
    last_name: string | null
  }
  last_reminder_sent: string | null
  can_send_reminder: boolean
  subject_user: {
    id: number
    name: string
    last_name: string | null
  }
  type: InvitationType
}

export type InvitationGroup = Invitation[]

export interface UserProfileSettingsInfo {
  name: string
  last_name: string
  nickname: string
}

export enum NumberOfClinicians {
  one = "1",
  twoToFive = "2-5",
  sixToTen = "6-10",
  elevenToTwenty = "11-20",
  twentyOnePlus = "21+",
}

export type ResourceTag = {
  id: number
  title: string
}

export enum ToastType {
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
}

export enum TargetBehaviorStatus {
  BASELINING = "baselining",
  STABLE = "stable",
  IMPROVING = "improving",
  REGRESSING = "regressing",
  MASTERED = "mastered",
}

export type TargetBehavior = {
  id: number
  creator: {
    id: number
    name: string
    last_name?: string
    email: string
  } | null
  user: any
  date_created: string
  title: string
  description: string
  preventive_strategies: {
    id: number
    title: string
  }[]
  status: TargetBehaviorStatus
  recent_7D_incident_count: number
  previous_7D_incident_count: number
  used_in_incident_logs: boolean
  incident_log_data_type: IncidentLogDataType
}

export enum BehaviorSkillsPanelContentType {
  EDIT_TARGET_BEHAVIOR,
  VIEW_QUEST,
}

export enum InvitationType {
  LINK_THERAPIST_PATIENT = "link_therapist_patient",
  LINK_PATIENT_FROM_THERAPIST = "link_patient_from_therapist",
  CLINIC_MEMBER = "clinic_member",
}

export enum SubscriptionLimitType {
  NUM_CLINICIANS = "num_clinicians",
  NUM_LINKED_PATIENTS = "num_linked_patients",
}

export interface SubscriptionLimit {
  id: number
  type: SubscriptionLimitType
  max_value: number
  current_value: number
}

export interface Practice {
  id: number
  name: string
  date_created: string
  has_agreed_baa: boolean
  profiles: PracticeMemberProfile[]
  subscription_limits: SubscriptionLimit[]
  type: GroupType
}

export interface PracticeMemberProfile {
  user: {
    id: number
    name: string
    last_name: string | null
    email: string
    date_joined: string
  }
  role: UserRole
  nickname: string
  profession: string
}

export enum ParentTrainingStatus {
  INACTIVE = "inactive",
  IN_PROGRESS = "in_progress",
  MASTERED = "mastered",
}

export interface TrendLine {
  start: { x: number; y: number }
  end: { x: number; y: number }
}

export type TrainingMasteryProgressGraphDataPoint =
  ParentTrainingMasteryProgress & {
    date: number
    percentage?: number
  }

export interface ProgressNote {
  texts: {
    body: string
    key: string
  }[]
}

export interface ProgressNotesResponse {
  assigned_notes: ProgressNote[]
  progress_notes: ProgressNote[]
}

export enum PatientInfoTab {
  BEHAVIORS = "behaviors",
  HOME_ROUTINES = "home-routines",
  PARENT_TRAINING = "parent-training",
  PATIENT_DETAILS = "patient-details",
}

export enum ParentTrainingTab {
  SUMMARY = "summary",
  MODULES = "modules",
  DATA_TASKS = "data-tasks",
  TODO_TASKS = "to-do-tasks",
}

export interface ParentTrainingDataCollectionTask {
  id: number
  training_id: number
  title: string // max 255 characters
  description: string | null // no max char
  data_type: IncidentLogDataType | null
  assigner: User
  child: User
  date_assigned: string
}

export enum DataSCView {
  GRAPH,
  TABLE,
}

export interface PatientGroup {
  id: number
  type: string
  name: string | null
  date_created: number
  profiles: (PracticeMemberProfile | FamilyMemberProfile)[]
}
