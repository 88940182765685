import { create } from "zustand"

interface GetStartedModalStore {
  isOpen: boolean
  onOpen: (step: number) => void
  onClose: () => void
  step: number | null
}

export const useGetStartedModal = create<GetStartedModalStore>((set) => ({
  isOpen: false,
  onOpen: (step) => set({ isOpen: true, step: step }),
  onClose: () => set({ isOpen: false }),
  step: null,
}))
