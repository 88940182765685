import {
  FlexBox,
  JoonUIColor,
  ParentTraining,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { ParentTrainingStatusColors } from "../../../constants"
import { ParentTrainingTab, PatientInfoTab } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { StatusBar } from "../behaviorsIncidents/AddTargetBehaviorModal"

const ParentTrainingItem = ({
  parentTraining,
}: {
  parentTraining: ParentTraining
}) => {
  const navigate = useNavigate()
  const { userId, trainingId } = useParams()
  const [isHovered, setIsHovered] = useState(false)
  const isSelected = Number(trainingId) === parentTraining.id

  const navigateToTraining = () => {
    navigate(
      `/patients/${userId}/${PatientInfoTab.PARENT_TRAINING}/${parentTraining.id}/${ParentTrainingTab.SUMMARY}`
    )
    trackAnalyticEvent(ANALYTIC_EVENTS.view_parent_training)
  }

  return (
    <button
      onClick={navigateToTraining}
      style={{
        padding: 0,
        width: "100%",
        borderRadius: SPACING.space2,
        background: isSelected
          ? JoonUIColor.background.lightBlue
          : JoonUIColor.background.primaryNeutral,
        outline:
          isSelected || isHovered
            ? `2px solid ${JoonUIColor.semantic.primary}`
            : undefined,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <FlexBox
        direction="row"
        justify="space-between"
        align="center"
        wrap={false}
        style={{
          padding: SPACING.space4,
          border: `1px solid ${JoonUIColor.border.default}`,
          borderRadius: SPACING.space2,
        }}
      >
        <FlexBox
          direction="row"
          align="center"
          wrap={false}
          gap={SPACING.space2}
        >
          <StatusBar
            color={ParentTrainingStatusColors[parentTraining.status].bar}
            height={SPACING.space10}
          />
          <FlexBox direction="column">
            <Typography variant="caption" textAlign="left">
              {parentTraining.title}
            </Typography>
            <Typography variant="bodyXSmall" textAlign="left">
              Started on: {dayjs(parentTraining.date_created).format("MMM D")}
            </Typography>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </button>
  )
}

export default ParentTrainingItem
