import { GuideItem } from "@joonapp/web-shared"

import { useGetStartedModal } from "./useGetStartedModal"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { sessionManager } from "../../util/storage"

type Props = {
  step: number
  title: string
  icon: string
  time: string
}

const GetStartedItem = ({ step, title, time }: Props) => {
  const { onOpen } = useGetStartedModal()

  const onClick = () => {
    onOpen(step)
    trackAnalyticEvent(ANALYTIC_EVENTS.get_started_guide_item_click, {
      section: getStartedSteps[step],
    })
    sessionManager.setGuideStepCompleted(step)
  }

  return (
    <GuideItem
      title={title}
      subtitle={time}
      isViewed={sessionManager.hasCompletedGuideStep(step)}
      onClick={onClick}
    />
  )
}

export default GetStartedItem

export const getStartedSteps = {
  1: "what-is-joon",
  2: "who-joon-is-for",
  3: "introducing-to-parents",
} as any
