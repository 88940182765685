import {
  Identify,
  identify,
  init,
  reset,
  track,
  setGroup,
  setUserId,
  add,
} from "@amplitude/analytics-browser"
import Hotjar from "@hotjar/browser"
import ReactPixel, { AdvancedMatching } from "react-facebook-pixel"

import { HotjarPlugin } from "./hotjarPlugin"
import { isVercelDeployment } from "../constants"

export const ANALYTIC_EVENTS = {
  //// Auth
  therapist_login: "therapist_login", // Sign in with email/pw event start
  therapist_login_approved: "therapist_login_approved", // Sign in with email/pw approved
  create_login: "create_login", // Sign up with email/pw approved
  google_login_approved: "google_login_approved", // Sign in with google approved
  google_signup_approved: "google_signup_approved", // Sign up with google approved
  therapist_signup: "therapist_signup", // Sign up with email/pw or google, sending all params

  //// Onboarding
  view_onboarding_page: "view_onboarding_page", // User views onboarding page
  initiate_onboarding: "initiate_onboarding", // User clicks on "Get Started"
  onboard_add_user_info: "onboard_add_user_info", // User hits continue after adding info
  onboarding_question: "onboarding_question", // User answers onboarding question
  onboard_has_account_login_button_click:
    "onboard_has_account_login_button_click", // User clicks on Login Herebutton
  onboard_has_clinic_button_click: "onboard_has_clinic_button_click", // User clicks on "enter it here" if they had clinic

  //// Welcome tutorial
  welcome_tutorial_close: "welcome_tutorial_close", // User clicks X on tutorial modal
  welcome_tutorial_add_patient: "welcome_tutorial_add_patient", // User clicks Get Started with patient
  welcome_tutorial_more_questions: "welcome_tutorial_more_questions", // User clicks on secondary button to Get Started
  welcome_tutorial_start: "welcome_tutorial-start", // Welcome tutorial starts

  // Dashboard
  load_linked_patients: "load_linked_patients", // List of patients loads
  add_patient_button_click: "add_patient_button_click", // User clicks on add_patient button
  add_patient_send_email: "add_patient_send_email", // User clicks on send email to patient
  add_patient_send_text: "add_patient_send_text", // User clicks on send text to patient
  view_patient_details: "view_patient_details", // User clicks on patient item to navigate to patient page
  webinar_click_interested: "webinar_click_interested", // User clicks on webinar link
  webinar_scheduled: "webinar_scheduled", // User clicks on webinar link
  webinar_times_dont_work: "webinar_times_dont_work", // User clicks on webinar times dont work
  dummy_view_patient_details: "dummy_view_details", // User clicks on dummy patient item to navigate to patient page
  send_reminder: "send_reminder", // User sends reminder to parent { type }
  accept_parent_invite: "accept_parent_invite", // User accepts parent invite
  reject_parent_invite: "reject_parent_invite", // User rejects parent invite
  send_outgoing_invite_reminder: "send_outgoing_invite_reminder", // User sends invite reminder
  delete_outgoing_invite: "delete_outgoing_invite", // User deletes invite

  //// Stats page
  group_quest_stats: "group_quest_stats", // User groups quest stats - category, routine, etc.
  quest_stats_card_click: "quest_stats_card_click", // User clicks on stat card - params: {type: 'total' | 'missing' | }
  quest_stats_interval_select: "quest_stats_interval_select", // Filter based on interval - params: { selection: 'Week' | 'Month' | 'Day' }
  quest_stats_date_select: "quest_stats_date_select", // Filter based on date range - params: { selection: 'Past 4 weeks' | 'Past 8 weeks' | 'Past 12 weeks' }
  quest_stats_row_select: "quest_stats_row_select",

  //// Nav
  navigate_settings: "navigate_settings", // User navigates on settings button
  navigate_linked_patients: "navigate_linked_patients", // User navigates to linked patients page
  navigate_getting_started: "navigate_getting_started", // User navigates to getting started page
  navigate_security: "navigate_security", // User navigates to linked patients page
  navigate_resources: "navigate_resources", // User navigates to resources page
  patient_info_tab_click: "patient_info_tab_click", // User navigates to patient info tab
  weekly_progress_table_toggle: "weekly_progress_table_toggle", // User toggles weekly progress table

  //// Getting Started
  get_started_section_click: "get_started_section_click", // User clicks on get started button { section: 'how-joon-works' | 'test-apps' | 'get-family-started' | 'add-patient' }
  get_started_guide_item_click: "get_started_guide_item_click", // User clicks on get started guide item { item: 'how-joon-works' | 'test-apps' | 'get-family-started' | 'add-patient' }
  open_guide_video_summary: "open_guide_video_summary", // User clicks on open guide video summary

  //// HIPAA
  hipaa_section_click: "hipaa_section_click", // User clicks on HIPAA section button { section: 'compliance' | 'privacy-policy' | 'pii-policy' }

  //// Settings
  settings_invite_clinic_member: "settings_invite_clinic_member", // User clicks on invite clinic member
  settings_change_role: "settings_change_role", // User changes role to therapist. body: { newRole: therapist | admin}
  settings_save_personal: "settings_save_personal", // User saves personal info
  settings_save_clinic: "settings_save_clinic", // User saves clinic info
  settings_remove_clinic_member: "settings_remove_clinic_member", // User removes clinic member

  //// Other
  auth_code_copy_click: "auth_code_copy_click", // User clicks on copy auth code, params: location + type

  //// Browser navigation
  page_refresh: "page_refresh", // User refreshes page
  page_close: "page_close", // User closes page
  page_back_forward: "page_back_forward", // User navigates away using back or forward button

  //// Behaviors / frequency targets
  open_add_behavior: "open_add_behavior", // User opens add behavior view
  add_behavior: "add_behavior", // User adds a behavior + target
  open_add_target: "open_add_frequency_target", // User opens view to add target to existing behavior
  add_target: "add_frequency_target", // User adds a frequency target
  open_edit_target: "open_edit_behavior", // User opens edit behavior view
  edit_target: "edit_behavior", // User edits a frequency target, // param: isTherapistCreated
  delete_target: "delete_target", // User deletes a frequency target // params: isTherapistCreated

  //// Target behaviors
  open_edit_target_behavior: "open_edit_target_behavior", // User opens edit a target behavior view
  edit_target_behavior: "edit_target_behavior", // User edits a target behavior
  open_add_target_behavior: "open_add_target_behavior", // User opens add target behavior view
  add_target_behavior: "add_target_behavior", // User adds a target behavior
  delete_target_behavior: "delete_target_behavior", // User deletes a target behavior

  //// Parent Training
  view_parent_training: "view_parent_training", // User views a parent training { status }
  open_add_parent_training: "open_add_parent_training", // User opens add parent training view
  add_parent_training: "add_parent_training", // User adds a parent training
  open_change_parent_training_status: "open_change_parent_training_status", // User opens change parent training status view { status }
  change_parent_training_status: "change_parent_training_status", // User changes parent training status { status }
  open_edit_parent_training: "open_edit_parent_training", // User opens edit parent training view
  edit_parent_training: "edit_parent_training", // User edits a parent training
  open_delete_parent_training: "open_delete_parent_training", // User opens delete parent training view
  delete_parent_training: "delete_parent_training", // User deletes a parent training
  open_add_modules: "open_add_modules", // User opens add modules view
  filter_learning_modules: "filter_learning_modules", // Params: { category: filter tag, location: 'resources', 'parent_task'}
  add_module_to_list: "add_module_to_list", // User adds a module to a list when adding modules
  remove_module_from_list: "remove_module_from_list", // User removes a module from a list when adding modules
  save_modules: "save_modules", // User saves modules when adding modules
  open_add_parent_task: "open_add_parent_task", // User opens add task view
  add_parent_task: "add_parent_task", // User adds a task
  open_edit_parent_task: "open_edit_parent_task", // User opens edit task view
  edit_parent_task: "edit_parent_task", // User edits a parent task
  open_archive_parent_task: "open_archive_parent_task", // User opens archive view
  archive_parent_task: "archive_parent_task", // User archives a parent task
  view_parent_task_details: "view_parent_task_details", // User views a parent task
  view_module_details: "view_module_details", // User views a module details
  open_archive_module_assignment: "open_archive_module_assignment", // User opens archive module view
  archive_module_assignment: "archive_module_assignment", // User archives a module
  view_modules_tab: "view_modules_tab", // User views modules tab
  view_tasks_tab: "view_tasks_tab", // User views tasks tab
  add_data_collection_task: "add_data_collection_task", // User adds a data collection task
  open_add_data_task: "open_add_data_task", // User opens add data task view
  add_data_task: "add_data_task", // User adds a data task
  open_edit_data_task: "open_edit_data_task", // User opens edit data task view
  edit_data_task: "edit_data_task", // User edits a data task
  open_delete_data_task: "open_delete_data_task", // User opens delete data task view
  delete_data_task: "delete_data_task", // User deletes a data task

  // Observational Data
  open_add_mastery_criteria: "open_add_mastery_criteria", // User opens add mastery criteria view
  add_mastery_criteria: "add_mastery_criteria", // User adds a mastery criteria
  open_edit_mastery_criteria: "open_edit_mastery_criteria", // User opens edit mastery criteria view
  edit_mastery_criteria: "edit_mastery_criteria", // User edits a mastery criteria
  toggle_observational_data: "toggle_observational_data", // User toggles between graph and table view
  open_add_observational_data: "open_add_observational_data", // User opens add observational data view
  add_observational_data: "add_observational_data", // User adds observational data
  open_edit_observational_data: "open_edit_observational_data", // User opens edit observational data view
  edit_observational_data: "edit_observational_data", // User edits observational data
  open_delete_observational_data: "open_delete_observational_data", // User opens delete observational data view
  delete_observational_data: "delete_observational_data", // User deletes observational data

  //// Resources
  add_resource: "add_resource", // User adds a resource
  view_resource: "view_resource", // User clicks on a ResourceItem
  favorite_resource: "favorite_resource", // User favorites a resource
  unfavorite_resource: "unfavorite_resource", // User unfavorites a resource

  //// Resource
  open_delete_resource: "open_delete_resource", // User opens delete resource view
  delete_resource: "delete_resource", // User deletes a resource
  open_edit_resource: "open_edit_resource", // User opens edit resource view
  edit_resource: "edit_resource", // User edits a resource
  download_resource: "download_resource", // User downloads a resource
  open_assign_module_to_training: "open_assign_module_to_training", // User clicks on assign as task
  assign_module_to_training: "assign_module_to_training", // User assigns a module to a training
  select_patient_for_task: "select_patient_for_task", // User selects patient for task
  click_suggest_resource: "click_suggest_resource", // User clicks on "can't find what you're looking for" button

  //// Paywall
  display_paywall_reminder: "display_paywall_reminder", // User sees paywall reminder
  open_upgrade_plan_request: "open_upgrade_plan_request", // User opens upgrade plan view
  send_upgrade_plan_request: "send_upgrade_plan_request", // User upgrades plan
}

if (import.meta.env.PROD && !isVercelDeployment) {
  // Initialize amplitude
  init(import.meta.env.VITE_AMPLITUDE_API_KEY as string, undefined, {
    minIdLength: 1,
  })
  add(new HotjarPlugin(3623886, 6))
  // Initialize hotjar
  Hotjar.init(3623886, 6)
}

export function analyticsUserDidLogin(parentId: string, practiceId: number) {
  // User id
  setUserId(parentId)

  // User properties
  const event = new Identify()
  event.set("practice_id", `${practiceId}`)
  identify(event)

  // Set practice group
  setGroup("practice", `${practiceId}`)
}

export function analyticsUserDidLogout() {
  reset()
}

export function trackAnalyticEvent(
  eventName: string,
  eventParams: { [param: string]: any } = {}
) {
  if (import.meta.env.DEV || isVercelDeployment) return
  eventParams.platform = "web_therapist"
  track(eventName, eventParams)
}

export function trackFBPixelEvent(
  eventName: string,
  eventParams: { [param: string]: any } = {},
  userInfo: { email: string; id: number } | null = null
) {
  if (import.meta.env.DEV || isVercelDeployment) return
  const options = {
    autoConfig: true,
    debug: false,
  }
  // pass user info if we are tracking an event where they are logged in
  // possible refactor by just putting in a hook that accesses the user,
  // so we don't have to pass it in every time
  const advancedMatching = { em: userInfo?.email } as AdvancedMatching
  if (userInfo?.id) eventParams.event_id = userInfo.id
  // Need to initialize pixel every time here because there is no way to
  // pass in the advanced matching params after initialization (if they log in)
  ReactPixel.init(
    import.meta.env.VITE_FB_PIXEL_ID as string,
    userInfo ? advancedMatching : undefined,
    options
  )
  ReactPixel.track(eventName, eventParams)
}
export const GA_EVENTS = {
  startOnboard: "AW-11405150449/cbOZCNvKi_UYEPGRs74q",
  createAccount: "AW-11405150449/Cg_2CN7Ki_UYEPGRs74q",
  linkPatient: "AW-11405150449/0NtkCNnLi_UYEPGRs74q",
}
export function trackGAEvent(gaEvent: string) {
  if (import.meta.env.DEV || isVercelDeployment) return
  gtag("event", "conversion", { send_to: gaEvent })
}
