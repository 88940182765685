import BackButton from "../../components/buttons/BackButton"
import { requireAuth } from "../../util/auth"

const TestApps = requireAuth(() => {
  return (
    <div className="page-wrapper">
      <BackButton />
      <div className="page-title">Test out both Joon Apps</div>
      <div className="testing-steps-card">
        <div className="section-title">How you can test the Parent App</div>
        <hr className="mt-12 mb-24" />
        <div className="testing-steps">
          <div className="testing-step">
            <div className="testing-step-image">
              <img
                src="/images/getStarted/parent1.svg"
                alt="parent app step 1"
              />
            </div>
            <div className="testing-step-content">
              <div className="step-number">1</div>
              <div className="testing-step-text">
                <div className="mb-12">
                  <span className="bold">For iPhone: </span>
                  Download the Parent App from the{" "}
                  <a
                    className="blue-text semibold"
                    target="blank"
                    rel="noreferrer"
                    href="https://apps.apple.com/us/app/
                  joon-app-adhd-chores-games/id1482225056"
                  >
                    iOS App Store
                  </a>
                </div>
                <div>
                  <span className="bold">For Android: </span>
                  <a
                    className="blue-text semibold"
                    target="blank"
                    rel="noreferrer"
                    href="https://my.joonapp.io/welcome"
                  >
                    Click here
                  </a>{" "}
                  to create an account.
                </div>
              </div>
            </div>
          </div>
          <div className="testing-step">
            <div className="testing-step-image">
              <img
                src="/images/getStarted/parent2.svg"
                alt="parent app step 2"
              />
            </div>
            <div className="testing-step-content">
              <div className="step-number">2</div>
              <div className="testing-step-text">
                Open the app, select{" "}
                <span className="purple-text">Therapist</span>, select{" "}
                <span className="purple-text">Yes </span>
                and enter your login credentials that you used to sign up for
                this Clinician Dashboard
              </div>
            </div>
          </div>
          <div className="testing-step">
            <div className="testing-step-image">
              <img
                src="/images/getStarted/parent3.svg"
                alt="parent app step 3"
              />
            </div>
            <div className="testing-step-content">
              <div className="step-number">3</div>
              <div className="testing-step-text">
                Tap <span className="purple-text">Family</span>, tap the{" "}
                <span className="purple-text">Settings </span>
                icon, tap <span className="purple-text">Manage Family</span>,
                and select
                <span className="purple-text"> Add Child </span>
                to add a dummy child account for testing!
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testing-steps-card">
        <div className="section-title">How you can test the Child App</div>
        <hr className="mt-12 mb-24" />
        <div className="testing-steps">
          <div className="testing-step">
            <div className="testing-step-image">
              <img src="/images/getStarted/child1.svg" alt="child app step 1" />
            </div>
            <div className="testing-step-content">
              <div className="step-number">4</div>
              <div className="testing-step-text" style={{ maxWidth: "280px" }}>
                First follow steps 1-3 above to get the Parent App set up. Then
                download the Kids App from any App Store by searching{" "}
                <span className="purple-text">Joon Pet Game</span>
                <br />
                <div className="body-text2 italic-text mt-12">
                  (available on{" "}
                  <a
                    href="https://play.google.com/store/apps/details/Joon_Adventure_Game?id=com.JoonAppInc.JoonKids&gl=US&pli=1"
                    target="blank"
                    rel="noreferrer"
                  >
                    Android
                  </a>
                  ,{" "}
                  <a
                    href="https://apps.apple.com/us/app/joon-pet-game/id1631871208"
                    target="blank"
                    rel="noreferrer"
                  >
                    Apple
                  </a>
                  , &{" "}
                  <a
                    href="https://www.amazon.com/dp/B0B6D7F3YW"
                    target="blank"
                    rel="noreferrer"
                  >
                    Amazon)
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="testing-step">
            <div className="testing-step-image">
              <img src="/images/getStarted/child2.svg" alt="child app step 2" />
            </div>
            <div className="testing-step-content">
              <div className="step-number">5</div>
              <div className="testing-step-text">
                Open the app, select
                <span className="purple-text"> Enter Code </span>
                and enter the Child Login Code which can be found by opening the
                Parent App and tapping the
                <span className="purple-text"> Family Tab</span>. The code is at
                the top of the screen
              </div>
            </div>
          </div>
          <div className="testing-step">
            <div className="testing-step-image">
              <img src="/images/getStarted/child3.svg" alt="child app step 3" />
            </div>
            <div className="testing-step-content">
              <div className="step-number">6</div>
              <div className="testing-step-text">
                Go through the tutorial to learn the fundamentals for how to use
                the kids app and start exploring the game of Joon!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default TestApps
