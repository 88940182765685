import {
  AuthCode,
  AuthCodeType,
  Button,
  FlexBox,
  JoonUIColor,
  Shadow,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useEffect } from "react"

import ClientPageToggle, {
  ClientPageToggleOption,
  useClientPageToggleStore,
} from "./ClientPageToggle"
import Invitations from "./invitations/Invitations"
import PatientsTable from "./PatientsTable"
import { QuickActionMobileButtonGroup } from "../../components/buttons/QuickActionMobileButton"
import PageContentWrapper from "../../components/layout/PageContentWrapper"
import { useAddPatientModalStore } from "../../components/modals/AddPatientModal"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import PatientPaywallReminder from "../../components/paywall/PatientPaywallReminder"
import usePaywall from "../../components/paywall/usePaywall"
import {
  useAuthCodeQuery,
  usePatientGroupsQuery,
  useUserQuery,
} from "../../networking/queries"
import { SubscriptionLimitType } from "../../types"
import {
  ANALYTIC_EVENTS,
  GA_EVENTS,
  trackAnalyticEvent,
  trackFBPixelEvent,
  trackGAEvent,
} from "../../util/analytics"
import { requireAuth } from "../../util/auth"

const PatientsDashboard = requireAuth(() => {
  const { data: patientGroups, isLoading } = usePatientGroupsQuery()
  usePatientGroupsQuery()
  const { selectedOption: pageView } = useClientPageToggleStore()
  const { user } = useUserQuery()

  const { onOpen: openAddPatientModal } = useAddPatientModalStore()
  const isMobile = useMediaQuery("(max-width:800px)")
  const { data: authCode } = useAuthCodeQuery(AuthCodeType.ADMIT_PATIENT)
  const { canAddToGroup: canAddToPatientGroup } = usePaywall(
    SubscriptionLimitType.NUM_LINKED_PATIENTS
  )

  // Analytic event so we know how many families a therapist has linked
  useEffect(() => {
    if (!patientGroups) return
    trackAnalyticEvent(ANALYTIC_EVENTS.load_linked_patients, {
      count: patientGroups?.length,
    })
    // FB Pixel event for when a user has linked a patient
    // imperfect - might need to be a server event for accuracy
    // or have a local flag to prevent multiple events if that is an issue
    if (patientGroups?.length > 0) {
      trackGAEvent(GA_EVENTS.linkPatient)
      trackFBPixelEvent("Subscribe", {}, { email: user?.email, id: user?.id })
    }
    // eslint-disable-next-line
  }, [patientGroups])

  return (
    <PageWrapper>
      <PageContentWrapper>
        <FlexBox
          direction="row"
          justify="space-between"
          align="center"
          gap={SPACING.space4}
          wrap={false}
        >
          <ClientPageToggle />
          {!isMobile && canAddToPatientGroup && (
            <FlexBox
              align="center"
              wrap={false}
              justify="flex-end"
              style={{ width: "fit-content" }}
            >
              <FlexBox
                align="center"
                wrap={false}
                style={{
                  marginRight: SPACING.space6,
                  border: `1px solid ${JoonUIColor.border.default}`,
                  width: "fit-content",
                  padding: `${SPACING.space2} ${SPACING.space4}`,
                  borderRadius: SPACING.space8,
                  boxShadow: Shadow.sunken,
                  background: JoonUIColor.background.lightGray,
                }}
              >
                <Typography variant="caption" style={{ whiteSpace: "nowrap" }}>
                  Client Invite Code:
                </Typography>
                <AuthCode code={authCode?.code || ""} />
              </FlexBox>
              <Button
                text="Invite clients"
                style={{ whiteSpace: "nowrap" }}
                onClick={openAddPatientModal}
              />
            </FlexBox>
          )}
        </FlexBox>
        {pageView === ClientPageToggleOption.CLIENTS ? (
          <FlexBox direction="column" gap={SPACING.space6}>
            <PatientPaywallReminder additionalText="Upgrade plan now to add more clients." />
            <div>
              <Typography variant="h2" style={{ marginBottom: SPACING.space2 }}>
                {isLoading
                  ? "Loading clients..."
                  : patientGroups && patientGroups?.length > 0
                  ? ` ${patientGroups.length} active Joon clients`
                  : "You don't have any active Joon clients yet!"}
              </Typography>
            </div>
            <PatientsTable />
          </FlexBox>
        ) : (
          <Invitations />
        )}
        <QuickActionMobileButtonGroup
          buttons={[
            {
              text: "Invite clients",
              onClick: openAddPatientModal,
            },
          ]}
        />
      </PageContentWrapper>
    </PageWrapper>
  )
})

export default PatientsDashboard
