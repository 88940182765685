import { faArrowLeft, faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { PatientNavSelect } from "../../components/nav/PatientInfoNav"

const PatientInfoSidebar = () => {
  const [isHoveredBackButton, setIsHoveredBackButton] = useState(false)
  const navigate = useNavigate()

  const isMobile = useMediaQuery("(max-width: 800px)")

  if (isMobile) return null

  return (
    <FlexBox
      direction="column"
      wrap={false}
      gap={SPACING.space3}
      style={{
        width: isMobile ? "100vw" : "min(225px, 100vw)",
        height: "100dvh",
        borderRight: `1px solid ${JoonUIColor.border.default}`,
        zIndex: 500,
        padding: SPACING.space4,
        background: JoonUIColor.background.primaryNeutral,
        position: isMobile ? "absolute" : "sticky",
        top: 0,
      }}
    >
      <button
        onClick={() => navigate("/patients")}
        style={{ padding: 0 }}
        onMouseEnter={() => setIsHoveredBackButton(true)}
        onMouseLeave={() => setIsHoveredBackButton(false)}
      >
        <FlexBox gap={SPACING.space2} wrap={false} align="center">
          <FontAwesomeIcon
            icon={isHoveredBackButton ? faArrowLeft : faChevronLeft}
            size="sm"
            width={16}
          />
          <Typography variant="bodySmall">All clients</Typography>
        </FlexBox>
      </button>
      <PatientNavSelect />
    </FlexBox>
  )
}

export default PatientInfoSidebar
