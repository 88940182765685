import { Incident } from "../types"
import { createJoonAPIClient } from "../util/joon-api"

export const getIncidents = async ({
  userId,
  targetBehaviorId,
  preventiveStrategyId,
  reporterId,
}: {
  userId: number
  targetBehaviorId?: number
  preventiveStrategyId?: number
  reporterId?: number
}) => {
  const API = createJoonAPIClient()

  return API.get<{ results: Incident[] }>(`api/incident-logs/`, {
    params: {
      user_id: userId,
      preventive_strategy_id: preventiveStrategyId,
      target_behavior_id: targetBehaviorId,
      reporter_id: reporterId,
    },
  }).then((res) => res.data.results)
}

export const getExportLink = async ({ userId }: { userId: number }) => {
  const API = createJoonAPIClient()

  const response = await API.get("api/incident-logs/export/", {
    params: { user_id: userId },
  })

  return response.data
}

export const markIncidentAsRead = async (incidentId: number) => {
  const API = createJoonAPIClient()
  return API.post(`api/incident-logs/${incidentId}/read/`)
}
