import { useEffect } from "react"
import { useLocation } from "react-router-dom"

import useOnboarding from "./useOnboarding"

const useNewClinicMember = () => {
  const { setStep, setHasClinic, setClinicCode } = useOnboarding()

  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const clinicCode = queryParams.get("clinic_code")

    if (clinicCode) {
      setStep(1)
      setHasClinic(true)
      setClinicCode(clinicCode)
    }
  }, [location, setStep, setHasClinic, setClinicCode])
}

export default useNewClinicMember
