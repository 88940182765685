import {
  AuthCode,
  AuthCodeType,
  Button,
  CalloutBox,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  Shadow,
  SPACING,
  TextButton,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { create } from "zustand"

import { sendEmailInvite } from "../../networking/authentication"
import { QUERY_KEYS, useAuthCodeQuery } from "../../networking/queries"
import { SubscriptionLimitType } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import PatientPaywallReminder from "../paywall/PatientPaywallReminder"
import usePaywall from "../paywall/usePaywall"
import { createToast } from "../toast/Toast"

interface AddPatientModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useAddPatientModalStore = create<AddPatientModalStore>((set) => ({
  isOpen: false,
  onOpen: () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.add_patient_button_click)
    set({ isOpen: true })
  },
  onClose: () => set({ isOpen: false }),
}))

export const AddPatientModal = () => {
  const { isOpen, onClose } = useAddPatientModalStore()

  const { data: authCode } = useAuthCodeQuery(AuthCodeType.ADMIT_PATIENT)

  const { canAddToGroup: canAddToPatientGroup } = usePaywall(
    SubscriptionLimitType.NUM_LINKED_PATIENTS
  )

  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const isMobile = useMediaQuery("(max-width: 600px)")
  const queryClient = useQueryClient()

  const sendText = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.add_patient_send_text)
    const textInstructions = `Hi, I'd like to start using Joon with your child to help manage your child's routine at home.

To get started, sign up at my.joonapp.io and enter my therapist code: ${
      authCode?.code || ""
    }`
    const message = encodeURIComponent(textInstructions)
    window.open(`sms:?&body=${message}`, "_blank")
    onClose()
  }

  const emailInstructions = async () => {
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    if (!isValidEmail) {
      createToast({ title: "Please enter a valid email", type: "error" as any })
      return
    }
    try {
      setIsLoading(true)
      await sendEmailInvite(email, authCode.id)
      trackAnalyticEvent(ANALYTIC_EVENTS.add_patient_send_email)
      createToast({ title: "Email invite sent!", type: "success" as any })
      onClose()
      setEmail("")
      queryClient.invalidateQueries([QUERY_KEYS.SENT_INVITES])
    } catch (error) {
      createToast({
        title: "Failed to send email invite",
        type: "error" as any,
      })
    }
    setIsLoading(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader
        title="Invite clients to Joon"
        onClose={onClose}
        showBorderBottom
      />
      <FlexBox
        direction="column"
        gap={SPACING.space6}
        style={{
          padding: SPACING.space6,
          paddingTop: SPACING.space4,
          height: "fit-content",
          width: "min(500px, 95vw)",
        }}
      >
        {!canAddToPatientGroup ? (
          <PatientPaywallReminder
            additionalText="Upgrade now to invite more clients."
            buttonType="primary"
          />
        ) : (
          <>
            <FlexBox gap={SPACING.space4} direction="column">
              <div style={{ width: "100%" }}>
                <TextInput
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  label="Enter parent email address"
                  fullWidth
                  placeholder="Email"
                />
              </div>
              <CalloutBox fullWidth>
                {isMobile && (
                  <FlexBox
                    align="center"
                    wrap={false}
                    style={{
                      border: `1px solid ${JoonUIColor.border.default}`,
                      width: "fit-content",
                      margin: "0 auto",
                      marginBottom: SPACING.space2,
                      padding: `${SPACING.space2} ${SPACING.space4}`,
                      borderRadius: SPACING.space8,
                      boxShadow: Shadow.sunken,
                      background: JoonUIColor.background.lightGray,
                    }}
                  >
                    <Typography
                      variant="caption"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Client Invite Code:
                    </Typography>
                    <AuthCode code={authCode?.code || ""} />
                  </FlexBox>
                )}
                <div className="font-footnote">
                  Once connnected, you will gain access to the following data:
                </div>
                <div className="font-footnote">
                  <ul>
                    <li>Child data (names & birthdates)</li>
                    <li>Quest & questionnaire response data</li>
                    <li>Child progress data</li>
                  </ul>
                </div>
              </CalloutBox>
            </FlexBox>

            <FlexBox gap={SPACING.space2} justify="center">
              <Button
                text="Send Invite"
                fullWidth
                onClick={emailInstructions}
                isLoading={isLoading}
              />
              {isMobile && (
                <TextButton onClick={sendText}>
                  <Typography
                    variant="bodyBold"
                    color={JoonUIColor.text.primaryAccent}
                    textAlign="center"
                  >
                    or Invite via Text
                  </Typography>
                </TextButton>
              )}
            </FlexBox>
          </>
        )}
      </FlexBox>
    </Modal>
  )
}
export default AddPatientModal
