import {
  JoonUIColor,
  SelectInput,
  SPACING,
  Typography,
  UserRole,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useCallback, useMemo, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import useMobileSidebarStore from "./useMobileSidebarStore"
import { usePatientGroupsQuery } from "../../networking/queries"
import { examplePatientData } from "../../pages/patientsDashboard/exampleChild"
import { PatientInfoTab } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

export const PatientNavSelect = () => {
  const { data: patientGroups } = usePatientGroupsQuery()
  const { userId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const isExamplePatient = Number(userId) === 0

  const patients = useMemo(
    () =>
      isExamplePatient
        ? [examplePatientData]
        : patientGroups?.flatMap((group: any) =>
            group.members.filter(
              (member: any) => member.role === UserRole.PATIENT
            )
          ),
    [isExamplePatient, patientGroups]
  )

  const patientOptions = useMemo(
    () =>
      patients?.map((patient: any) => ({
        label: patient.user.name,
        value: patient.user.id,
      })) || [],
    [patients]
  )
  const handlePatientChange = useCallback(
    (selectedOption: { value: any; label: any }) => {
      const pathParts = location.pathname.split("/")
      const userIdIndex = pathParts.findIndex((part) => part === userId)
      if (userIdIndex !== -1 && userIdIndex + 1 < pathParts.length) {
        const newPath = `/patients/${selectedOption.value}/${
          pathParts[userIdIndex + 1]
        }`
        navigate(newPath)
      } else {
        navigate(`/patients/${selectedOption.value}`)
      }
    },
    [location.pathname, navigate, userId]
  )

  return (
    <>
      <SelectInput
        name="patient-select"
        options={patientOptions}
        selectedOption={
          patientOptions.find(
            (option: any) => option.value === Number(userId)
          ) as any
        }
        setSelectedOption={handlePatientChange}
        isMulti={false}
        fullWidth
        style={{
          borderRadius: SPACING.space2,
          border: `1px solid ${JoonUIColor.border.default}`,
        }}
      />
      {patientInfoTabs.map((tab, i) => (
        <NavButton tab={tab} key={i} />
      ))}
    </>
  )
}

const patientInfoTabs = [
  { label: "Parent training", value: PatientInfoTab.PARENT_TRAINING },
  { label: "Behaviors & Incidents", value: PatientInfoTab.BEHAVIORS },
  { label: "Home routines", value: PatientInfoTab.HOME_ROUTINES },
  { label: "Client details", value: PatientInfoTab.PATIENT_DETAILS },
]

export const NavButton = ({
  tab,
}: {
  tab: (typeof patientInfoTabs)[number]
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const { onClose } = useMobileSidebarStore()
  const navigate = useNavigate()
  const { userId } = useParams()
  const location = useLocation()
  const isMobile = useMediaQuery("(max-width: 800px)")
  const isActive = location.pathname.includes(tab.value)

  const onClick = () => {
    if (!isActive) {
      navigate(`/patients/${userId}/${tab.value}`)
      trackAnalyticEvent(ANALYTIC_EVENTS.patient_info_tab_click, { tab })
    }
    onClose()
  }

  return (
    <button
      style={{
        backgroundColor:
          (isActive || isHovered) && !isMobile
            ? JoonUIColor.background.lightAccent
            : "transparent",
        padding: SPACING.space2,
        borderRadius: SPACING.space2,
        cursor: "pointer",
        width: "100%",
      }}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Typography
        variant="bodySmall"
        textAlign="left"
        style={{ fontWeight: isActive ? "600" : "normal" }}
      >
        {tab.label}
      </Typography>
    </button>
  )
}
