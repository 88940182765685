import {
  AuthCode,
  AuthCodeType,
  Button,
  EditIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  Shadow,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"

import AddClinicMemberModal from "./AddClinicMemberModal"
import ClinicMemberTable from "./ClinicMemberTable"
import { useEditClinicInfoModalStore } from "./EditClinicInfoModal"
import useAddClinicMemberModalStore from "./useAddClinicMemberModalStore"
import useAdminUser from "./useAdminUser"
import PracticePaywallReminder from "../../components/paywall/PracticePaywallReminder"
import usePaywall from "../../components/paywall/usePaywall"
import { useAuthCodeQuery, usePracticeQuery } from "../../networking/queries"
import { SubscriptionLimitType } from "../../types"

const ClinicInformation = () => {
  const { practice } = usePracticeQuery()
  const { onOpen: openEditClinicInfoModal } = useEditClinicInfoModalStore()

  const { onOpen: openAddClinicMemberModal } = useAddClinicMemberModalStore()
  const isMobile = useMediaQuery("(max-width:800px)")
  const { data: authCode } = useAuthCodeQuery(AuthCodeType.GROUP)
  const { canAddToGroup: canAddtoPracticeGroup } = usePaywall(
    SubscriptionLimitType.NUM_CLINICIANS
  )
  const userIsAdmin = useAdminUser()

  if (!practice) return <></>

  return (
    <FlexBox direction="column" wrap={false} gap={SPACING.space4}>
      <Typography variant="h3">Clinic detail</Typography>
      <FlexBox
        justify="space-between"
        wrap={false}
        align="center"
        style={{
          padding: SPACING.space4,
          border: `1px solid ${JoonUIColor.border.default}`,
          borderRadius: SPACING.space2,
          width: "min(100%, 400px)",
          background: JoonUIColor.background.primaryNeutral,
        }}
      >
        <FlexBox direction="column">
          <Typography variant="bodyBold" textAlign="left">
            Clinic name
          </Typography>
          <Typography variant="body" textAlign="left">
            {practice.name}
          </Typography>
        </FlexBox>
        {userIsAdmin && (
          <TextButton onClick={openEditClinicInfoModal}>
            <EditIcon size={14} />
          </TextButton>
        )}
      </FlexBox>

      <FlexBox direction="column">
        <FlexBox
          justify="space-between"
          gap={SPACING.space2}
          direction={isMobile ? "column" : "row"}
          align={isMobile ? "flex-start" : "center"}
          wrap={false}
          style={{ padding: `${SPACING.space4} 0` }}
        >
          <Typography variant="h3">
            Clinic members ({practice.profiles?.length})
          </Typography>
          <FlexBox
            align="center"
            gap={SPACING.space2}
            wrap={false}
            style={{ width: "fit-content" }}
          >
            {canAddtoPracticeGroup && (
              <FlexBox
                align="center"
                style={{
                  border: `1px solid ${JoonUIColor.border.default}`,
                  width: "fit-content",
                  padding: `${SPACING.space2} ${SPACING.space4}`,
                  borderRadius: SPACING.space8,
                  boxShadow: Shadow.sunken,
                  background: JoonUIColor.background.lightGray,
                }}
              >
                <Typography variant="caption">Clinic code:</Typography>
                <AuthCode code={authCode?.code} />
              </FlexBox>
            )}
            {userIsAdmin && (
              <Button
                text="Invite clinician"
                onClick={openAddClinicMemberModal}
                style={{ whiteSpace: "nowrap" }}
              />
            )}
          </FlexBox>
        </FlexBox>
        <div
          style={{
            padding: `${SPACING.space4} ${SPACING.space4}`,
            width: "100%",
            border: `1px solid ${JoonUIColor.border.default}`,
            borderRadius: SPACING.space2,
            background: JoonUIColor.background.primaryNeutral,
          }}
        >
          <PracticePaywallReminder additionalText="Upgrade plan now to invite more team members." />
          <ClinicMemberTable members={practice.profiles} />
        </div>
      </FlexBox>
      <AddClinicMemberModal />
    </FlexBox>
  )
}

export default ClinicInformation
