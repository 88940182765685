import {
  MasteryCriteriaMeasurement,
  ParentTraining,
  ParentTrainingMasteryProgress,
  ParentTrainingStatus,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

import { ProgressNotesResponse } from "../types"
import { createJoonAPIClient } from "../util/joon-api"

export const addParentTraining = async (data: {
  user_id: number
  title: string
  mastery_criteria?: string | null
  module_ids?: number[]
  child_id: number
}) => {
  const API = createJoonAPIClient()

  return API.post("api/parent-trainings/", data).then((res) => res.data)
}

export const editParentTraining = async (data: {
  id: number
  title?: string
  mastery_criteria?: string | null
  mastery_criteria_measurement?: MasteryCriteriaMeasurement
  status?: ParentTrainingStatus
  module_ids?: number[]
}) => {
  const API = createJoonAPIClient()

  return API.patch(`api/parent-trainings/${data.id}/`, data).then(
    (res) => res.data
  )
}

export const getParentTrainings = async (parentId?: number) => {
  const API = createJoonAPIClient()

  return API.get<{ results: ParentTraining[] }>("api/parent-trainings/", {
    params: { user_id: parentId },
  }).then((res) => res.data?.results)
}

export const getParentTraining = async (id: number) => {
  const API = createJoonAPIClient()

  return API.get<ParentTraining>(`api/parent-trainings/${id}/`).then(
    (res) => res.data
  )
}

export const deleteParentTraining = async (id: number) => {
  const API = createJoonAPIClient()
  return API.delete(`api/parent-trainings/${id}/`)
}

export const getTrainingMasteryProgress = async (data: {
  training_id?: number
  min_date?: string
  max_date?: string
}) => {
  const API = createJoonAPIClient()

  return API.get<{ results: ParentTrainingMasteryProgress[] }>(
    "api/parent-training-mastery-progress/",
    {
      params: data,
    }
  ).then((res) => res.data.results)
}

export const editObservationalData = async (data: {
  id?: number
  date?: string
  frequency: number
  base_count?: number
  observation_notes: string
}) => {
  const API = createJoonAPIClient()

  return API.patch(
    `api/parent-training-mastery-progress/${data.id}/`,
    data
  ).then((res) => res.data)
}

export const deleteObservationalData = async (id: number) => {
  const API = createJoonAPIClient()

  return API.delete(`api/parent-training-mastery-progress/${id}/`).then(
    (res) => res.data
  )
}

export const addObservationalData = async (data: {
  training_id: number
  date: string
  frequency: number
  base_count?: number
  observation_notes?: string
}) => {
  const API = createJoonAPIClient()

  return API.post(`api/parent-training-mastery-progress/`, data).then(
    (response) => response.data
  )
}

export const generateProgressNote = async ({
  parentTrainingId,
  startDate,
  endDate,
}: {
  parentTrainingId: number
  startDate: Date
  endDate: Date
}) => {
  const API = createJoonAPIClient()
  const response = await API.get<ProgressNotesResponse>(
    `/api/parent-trainings/${parentTrainingId}/notes/`,
    {
      params: {
        min_date: dayjs(startDate).format("YYYY-MM-DD"),
        max_date: dayjs(endDate).format("YYYY-MM-DD"),
      },
    }
  )
  return response.data
}
