import { SPACING } from "@joonapp/web-shared"
import { Link } from "react-router-dom"

import GetStartedItem from "./GetStartedItem"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { requireAuth } from "../../util/auth"
import Webinar from "../patientsDashboard/Webinar"

const GetStartedSteps = {
  overview: 1,
  patientFit: 2,
  introducingJoon: 3,
  therapistBenefits: 4,
}

const GetStarted = requireAuth(() => {
  return (
    <div className="page-wrapper">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="page-title">Getting Started on Joon</div>
      </div>
      <div
        style={{
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "20px",
          marginBottom: "16px",
        }}
      >
        Bite-sized videos to help you quickly take advantage of Joon with your
        clients.
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "100%",
          maxWidth: "600px",
          marginBottom: SPACING.space6,
        }}
      >
        <GetStartedItem
          step={GetStartedSteps.overview}
          icon="/images/icons/book-open.svg"
          title="What is Joon and how does it work?"
          time="4 minutes"
        />
        <GetStartedItem
          step={GetStartedSteps.patientFit}
          icon="/images/icons/smartphone.svg"
          title="Kids and parents Joon works well for"
          time="2 minutes"
        />
        <GetStartedItem
          step={GetStartedSteps.introducingJoon}
          icon="/images/icons/share-2.svg"
          title="Introducing Joon to parents"
          time="2 minutes"
        />
      </div>
      <Webinar />

      <div
        style={{
          maxWidth: "300px",
          lineHeight: "22px",
          fontSize: "20px",
          fontWeight: "600",
          marginTop: "30px",
          marginBottom: "4px",
        }}
      >
        Additional Resources
      </div>

      <div className="help-section">
        <Link
          to="/get-started/how-joon-works"
          className="help-card"
          onClick={() =>
            trackAnalyticEvent(ANALYTIC_EVENTS.get_started_section_click, {
              section: "how-joon-works",
            })
          }
        >
          <div className="help-card-title">How Joon Works</div>
          <div>
            Joon comes with two different apps for families to use, a Parent App
            and a Child App. Watch a few videos to learn how each app works and
            how the data connects to your clinical dashboard.
          </div>
          <div className="help-card-link">Watch Videos</div>
        </Link>{" "}
        <Link
          to="/get-started/test-apps"
          className="help-card"
          onClick={() =>
            trackAnalyticEvent(ANALYTIC_EVENTS.get_started_section_click, {
              section: "test-apps",
            })
          }
        >
          <div className="help-card-title">
            Test out the Parent & Child Apps
          </div>
          <div>
            Learn how to download the Parent and Child App so you can play
            around with both apps and familiarize yourself with how they work
            before recommending them to families.
          </div>
          <div className="help-card-link">View Instructions</div>
        </Link>
        <Link
          to="/get-started/families"
          className="help-card"
          onClick={() =>
            trackAnalyticEvent(ANALYTIC_EVENTS.get_started_section_click, {
              section: "get-family-started",
            })
          }
        >
          <div className="help-card-title">
            Getting a Family Started with Joon
          </div>
          <div>
            View our shareable guides that you can print and give to your
            clients with step by step instructions on how to get setup on Joon.
          </div>
          <div className="help-card-link">View Guides</div>
        </Link>
      </div>
    </div>
  )
})

export default GetStarted
