import { JoonUIColor } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useRef } from "react"

import useHiddenElement from "../../hooks/useHiddenElement"

interface Props {
  isOpen: boolean
  children: React.ReactNode
  from?: "left" | "right"
}

const SidePanel = ({ isOpen, children, from = "right" }: Props) => {
  const isMobile = useMediaQuery("(max-width: 800px)")
  const sidebarRef = useRef<HTMLDivElement>(null)

  useHiddenElement(sidebarRef, !isOpen)

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        minWidth: isMobile ? "min(450px, 100vw)" : "375px",
        maxWidth: isMobile ? "min(450px, 100vw)" : "375px",
        height: "100dvh",
        background: JoonUIColor.background.primaryNeutral,
        borderLeft: `1px solid ${JoonUIColor.border.default}`,
        borderRight: `1px solid ${JoonUIColor.border.default}`,
        boxShadow: "-4px 6px 6px 0px rgba(0, 0, 0, 0.08)",
        overflow: "hidden",
        position: "absolute",
        zIndex: 960,
        right: from === "right" ? (isOpen ? 0 : "-110%") : "unset",
        left: from === "left" ? (isOpen ? 0 : "-110%") : "unset",
        top: 0,
        transition: "0.2s cubic-bezier(0.16, 1, 0.3, 1)",
      }}
      ref={sidebarRef}
    >
      {children}
    </div>
  )
}

export default SidePanel
