import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material"

import { getStartedSteps } from "./GetStartedItem"
import { useGetStartedModal } from "./useGetStartedModal"
import CustomModal from "../../components/modals/CustomModal"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

const GetStartedModal = () => {
  const { isOpen, onClose: closeGetStartedModal, step } = useGetStartedModal()

  return (
    <CustomModal isOpen={isOpen} handleClose={closeGetStartedModal}>
      <>
        {step === 1 ? (
          <Step1 />
        ) : step === 2 ? (
          <Step2 />
        ) : (
          step === 3 && <Step3 />
        )}
      </>
    </CustomModal>
  )
}

export default GetStartedModal

const Step1 = () => {
  const step = 1

  return (
    <div className="get-started-modal">
      <div className="mb-6" style={{ fontSize: "24px", fontWeight: "700" }}>
        What is Joon and how does it work?
      </div>
      <div className="video-frame">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/89YlOT0WoDY?t=22s"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className="reactPlayer"
          id="video1"
        />
      </div>
      <div className="video-timestamps">
        <div className="mb-6" style={{ fontSize: "24px", fontWeight: "700" }}>
          Video Contents
        </div>
        <div className="video-timestamp">
          <span className="timestamp-time">0:22</span>
          <span>Overview of Joon and how it helps</span>
        </div>
        <div className="video-timestamp">
          <span className="timestamp-time">1:25</span>
          <span>How the parent app works (demo)</span>
        </div>
        <div className="video-timestamp">
          <span className="timestamp-time">2:32</span>
          <span>How the child app works</span>
        </div>
        <div className="video-timestamp">
          <span className="timestamp-time">3:24</span>
          <span>Key Considerations</span>
        </div>
      </div>
      <Accordion
        className="accordion mt-12"
        onChange={(_event, expanded) => {
          if (!expanded)
            trackAnalyticEvent(ANALYTIC_EVENTS.open_guide_video_summary, {
              section: getStartedSteps[step],
            })
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ fontSize: "30px" }} />}
          className="accordion-summary"
        >
          Video Key Points
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <div className="subsection-title">Joon system overview</div>
          <div className="semibold">
            Joon is a completely digital reward system, that engages and
            motivates kids, while being simple for a parent to manage.{" "}
          </div>
          <div>It helps families with kids aged 5-13 with:</div>
          <ul>
            <li>Child engagement and independence</li>
            <li>A simpler behavior management system for the parent</li>
            <li>
              Overall consistency with routines, chores, and behavior plan tasks
            </li>
          </ul>

          <div className="subsection-title mt-18">How the parent app works</div>
          <div>
            The parent app serves as a behavior management system and resources
            tool for the parent.{" "}
            <span className="semibold">
              Parents can manage active routine tasks and behaviors, verify when
              they are completed, and measure progress over time.
            </span>
          </div>

          <div>
            <div className="semibold">Key functionality:</div>
            <ul>
              <li>Managing and verifying routine behaviors and tasks quests</li>
              <li>Personalized recommendations + resources</li>
              <li>Creating custom real-life rewards for further motivation</li>
              <li>Manage game limits and measure Progress</li>
            </ul>
          </div>

          <div className="subsection-title mt-18">How the child app works</div>
          <div>
            The child app is built to motivate kids to perform real-life
            behaviors. It’s been clinically shown to improve executive
            functioning, specifically around working memory, task initiation and
            task completion.
          </div>
          <div className="semibold">Key Functionality:</div>
          <ul>
            <li>
              Complete “Quests”: Do real-life rewards to get food to take care
              of your virtual pet(s)
            </li>
            <li>Virtual Pet: Take care of and customize virtual pets</li>
          </ul>

          <div className="subsection-title mt-18">Key Considerations</div>
          <div className="semibold">Pricing</div>
          <ul>
            <li>Free 7 day trial</li>
            <li>
              25% discount when referred by clinicians: <s>$90</s> $70 per year
              per family
            </li>
          </ul>
          <div className="semibold">Device accessibility</div>
          <ul>
            <li>
              The parent app is available on Apple devices, but we also have a
              web browser version available, accessed at{" "}
              <a href="https://my.joonapp.io">https://my.joonapp.io</a>
            </li>
            <li>
              The kid app is available on any mobile or tablet device (android,
              apple, chromebooks)
            </li>
          </ul>
          <div className="semibold">Screen-time Expectations</div>
          <ul>
            <li>
              <span className="semibold">
                Low Screen-time by design: 10 minutes per day on average,
              </span>{" "}
              which usually ties to about 1 hour of real-life behaviors
            </li>
            <li>
              <span className="semibold"> Parents can set up game limits</span>{" "}
              through the app, to further restrict screen-time
            </li>
            <li>
              If the device is taken away, parents can still track routines
              through the parent app, and{" "}
              <span className="semibold">
                parents can print out a physical version of the behavior list
                through the app
              </span>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

const Step2 = () => {
  const step = 2

  return (
    <div className="get-started-modal">
      <div className="mb-6" style={{ fontSize: "24px", fontWeight: "700" }}>
        Kids and parents Joon works well for
      </div>
      <div className="video-frame">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/aey4_ciJ3Ag"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className="reactPlayer"
        />
      </div>
      <div className="video-timestamps">
        <div className="mb-6" style={{ fontSize: "24px", fontWeight: "700" }}>
          Video Contents
        </div>
        <div className="video-timestamp">
          <span className="timestamp-time">0:14</span>
          <span>Child fit (age, diagnosis, accessibility, etc)</span>
        </div>
        <div className="video-timestamp">
          <span className="timestamp-time">1:26</span>
          <span>Parent Fit</span>
        </div>
      </div>
      <Accordion
        className="accordion mt-12"
        onChange={(_event, expanded) => {
          if (!expanded)
            trackAnalyticEvent(ANALYTIC_EVENTS.open_guide_video_summary, {
              section: getStartedSteps[step],
            })
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ fontSize: "30px" }} />}
          className="accordion-summary"
        >
          Video Key Points
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <div className="subsection-title">Child Fit</div>
          <div className="semibold">Joon works great for:</div>
          <ul>
            <li>Kids aged 6-12, with variance depending on the child.</li>
            <li>
              Kids who struggle with executive functioning, daily routines and
              occupational tasks.
            </li>
            <li>
              Kids diagnosed with ADHD, autism, anxiety, depression, ODD, or
              other behavior disorders, by improving executive functioning.
            </li>

            <li>
              Kids who like video games, for example, games like Pokemon,
              Roblox, Among Us, and Fortnite.
            </li>
          </ul>
          <div className="semibold">Accessibility</div>
          <ul>
            <li>
              Easy to understand - Joon has text-to-speech, and many of visual
              elements, so Joon still works well if the child struggles with
              reading.
            </li>
            <li>
              Positive reinforcement - Gameplay mechanics are designed with
              positive reinforcement, to reduce concerns of emotional regulation
              reactions.
            </li>
            <li>
              Device availability - Kids will need to have access to a mobile
              device to access the app, such as an smartphone, iPad, Android
              tablet, Fire Tablet, or Chromebook.
            </li>
          </ul>

          <div className="subsection-title mt-18">Parent Fit</div>
          <ul>
            <li>
              Device access - Parent ideally gives some access to a device for
              their child
              <ul>
                <li>
                  Joon on average is about 5-10 minutes of screen-time, and
                  usually used on weekdays.
                </li>
                <li>
                  Game limit features - Parents can limit usage through the app,
                  or even print out a physical version of the behavior list for
                  the child.
                </li>
              </ul>
            </li>

            <li>
              Joon works great with parents who struggle to be consistent with
              implementing behavior tools at home. It engages the child and
              makes it simpler for the parent to manage.
            </li>
            <li>
              Supports single parents, married parents (where we have
              functionality to add additional parents to the same family) and
              co-parents.
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

const Step3 = () => {
  const step = 3

  return (
    <div className="get-started-modal">
      <div className="mb-6" style={{ fontSize: "24px", fontWeight: "700" }}>
        Introducing Joon to parents
      </div>
      <div className="video-frame">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/cX8V8Vbu3uw"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className="reactPlayer"
        />
      </div>
      <div className="video-timestamps">
        <div className="mb-6" style={{ fontSize: "24px", fontWeight: "700" }}>
          Video Contents
        </div>
        <div className="video-timestamp">
          <span className="timestamp-time">0:15</span>
          <span>Health screen-time, parent and clinical testimonials</span>
        </div>
        <div className="video-timestamp">
          <span className="timestamp-time">1:30</span>
          <span>How it makes parents live’s easier </span>
        </div>
        <div className="video-timestamp">
          <span className="timestamp-time">2:04</span>
          <span>Key point summary (demo)</span>
        </div>
      </div>
      <Accordion
        className="accordion mt-12"
        onChange={(_event, expanded) => {
          if (!expanded)
            trackAnalyticEvent(ANALYTIC_EVENTS.open_guide_video_summary, {
              section: getStartedSteps[step],
            })
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ fontSize: "30px" }} />}
          className="accordion-summary"
        >
          Video Key Points
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <div className="subsection-title">
            Health screen-time, parent and clinical testimonials
          </div>
          <ul>
            <li>
              <span className="italic-text semibold">
                Minimal screen time required{" "}
              </span>
              - Joon requires 5-10 minutes per weekday of screen-time, while
              improving task completion and executive functioning in 80% of kids
              within 4-8 weeks.
            </li>
            <li>
              <span className="italic-text semibold">
                No ads or in-app purchases
              </span>{" "}
              - Only pay the subscription after the free trial. No sketchy ads
              or monetization techniques.
            </li>
            <li>
              <span className="italic-text semibold">Developed by experts</span>{" "}
              - Joe Raiker, PhD is Joon’s Director of Clinical Research and
              Strategy, and is a renown clinical psychologist in pediatric
              behavior disorders. Joon also has over a dozen clinical advisors.
            </li>
            <li>
              <span className="italic-text semibold">Trusted reviews</span> -
              100K+ parents have used Joon. We have 4.7 stars on the app store,
              and thousands of positive testimonials from parents and kids.
            </li>
          </ul>

          <div className="subsection-title mt-18">
            How it makes parents lives easier
          </div>
          <ul>
            <li>
              Reward system motivates kids - The game motivates kids to do their
              real-life responsibilities, resulting in less nagging and constant
              reminders from parents. This is usually the immediate relief
              parents feel when starting Joon, and they feel it on the first
              day.
            </li>
            <li>
              Easy to manage and be consistent - The mobile app keeps track of
              everything, eliminating the burden of managing paper charts and
              ticket reward systems.
            </li>
            <li>
              See progress over time - parents can view consistency and have
              deeper insight into how their kids are doing.
            </li>
          </ul>
          <div className="subsection-title mt-18">Key Point Summary</div>
          <ul>
            <li>
              Healthy screen-time, and only requires 5-10 minutes per day.
            </li>
            <li>
              Built and supported by top clinicians and loved by hundreds of
              thousands of parents.
            </li>
            <li>
              Removes nagging and constant reminders around daily routines,
              chores, homework, and more.
            </li>
            <li>
              Helps parents and children alike be more consistent day to day,
              removing guilt, and helping their child make behavioral progress.
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
