// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/autoplay"
import "swiper/css/pagination"
import "./onboardTutorial.scss"

import {
  Button,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useEffect, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { create } from "zustand"

import { useReceivedInvitesQuery } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { localStorageItems } from "../../util/storage"
import { useAddPatientModalStore } from "../modals/AddPatientModal"
import CustomModal from "../modals/CustomModal"

interface OnboardTutorialModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  currentIndex: number
  setCurrentIndex: (index: number) => void
}

export const useOnboardTutorialModal = create<OnboardTutorialModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => {
      localStorage.removeItem(localStorageItems.needsToSeeTutorial)
      trackAnalyticEvent(ANALYTIC_EVENTS.welcome_tutorial_close)
      set({ isOpen: false })
    },
    currentIndex: 0,
    setCurrentIndex: (index: number) => set({ currentIndex: index }),
  })
)

const OnboardTutorialModal = () => {
  const { isOpen, onOpen, onClose, currentIndex } = useOnboardTutorialModal()

  const location = useLocation()

  // Start tutorial when storage item is set
  useEffect(() => {
    if (!!localStorage.getItem(localStorageItems.needsToSeeTutorial)) {
      trackAnalyticEvent(ANALYTIC_EVENTS.welcome_tutorial_start)
      onOpen()
    }
  }, [onOpen, location])

  const isLastStep = currentIndex === slides.length

  return (
    <CustomModal
      isOpen={isOpen}
      handleClose={() => (isLastStep ? onClose() : undefined)}
      canClose={isLastStep}
    >
      <FlexBox
        direction="column"
        gap={SPACING.space6}
        align="unset"
        justify="unset"
        wrap={false}
        style={{
          padding: SPACING.space6,
          height: "fit-content",
          width: "min(400px, 95vw)",
        }}
      >
        {currentIndex < slides.length ? (
          <Carousel slides={slides} />
        ) : (
          isLastStep && <HavePatientInMindView />
        )}
      </FlexBox>
    </CustomModal>
  )
}
export default OnboardTutorialModal

function Carousel({ slides }: any) {
  const swiperRef = useRef<any>(null)
  const { currentIndex, setCurrentIndex, onClose } = useOnboardTutorialModal()
  const { data: invites } = useReceivedInvitesQuery()

  const onSlideChange = () => {
    setCurrentIndex(swiperRef.current.activeIndex)
  }

  const onNext = () => {
    if (currentIndex === slides.length - 1) {
      const hasPendingInvite = invites && invites.length === 1
      if (hasPendingInvite) onClose()
      else setCurrentIndex(currentIndex + 1)
    }
    swiperRef?.current?.slideNext()
  }

  return (
    <div className="carousel">
      <div className="slide-welcome">Welcome to Joon!</div>
      <Swiper
        className="testimonial-box"
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        pagination={{
          clickable: true,
        }}
        ref={swiperRef}
        spaceBetween={30}
        onInit={(swiper: any) => {
          swiperRef.current = swiper
          setCurrentIndex(swiper.activeIndex) // Set the initial index
        }}
        onSlideChange={onSlideChange}
      >
        {slides.map((slide: any) => (
          <SwiperSlide>
            <WelcomeSlide slide={slide} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Button
        onClick={onNext}
        text={slides[currentIndex]?.buttonText}
        fullWidth
      />
    </div>
  )
}

function WelcomeSlide({ slide }: { slide: Slide }) {
  return (
    <div className="slide">
      <div className="slide-text mb-12">{slide.text}</div>
      <img className="slide-image" src={slide.image} alt="" />
    </div>
  )
}

interface Slide {
  text: string | JSX.Element
  image: string
  buttonText: string
}

const slides: Slide[] = [
  {
    text: (
      <>
        <div className="slide-text-header mb-12">
          For therapists, Joon helps you...
        </div>
        <div className="font-footnote mb-16">
          Quickly monitor the consistency of treatment implementation
        </div>
        <div className="font-footnote">
          Identify changes in progress through daily, weekly, or monthly reviews
          of data
        </div>
      </>
    ),
    image: "/images/onboardTutorial/slide_therapist1.svg",
    buttonText: "Next",
  },
  {
    text: (
      <>
        <div className="slide-text-header mb-12">
          For therapists, Joon helps you...
        </div>
        <div className="font-footnote mb-16">
          Aid parents in managing and tracking their child’s behavioral goals at
          home
        </div>
      </>
    ),
    image: "/images/onboardTutorial/slide_therapist2.svg",
    buttonText: "Next: Parents' benefits",
  },
  {
    text: (
      <>
        <div className="slide-text-header mb-12">How Joon helps parents</div>
        <div className="font-footnote">View and set goals from anywhere</div>
        <div className="font-footnote">
          Schedule daily or weekly routines with ease
        </div>
      </>
    ),
    image: "/images/onboardTutorial/slide_parent1.svg",
    buttonText: "Next",
  },
  {
    text: (
      <>
        <div className="slide-text-header mb-12">How Joon helps parents</div>
        <div className="font-footnote">Review goals anywhere</div>
        <div className="font-footnote">
          Meaningfully reward goals completion
        </div>
      </>
    ),
    image: "/images/onboardTutorial/slide_parent2.svg",
    buttonText: "Next: Kids' benefits",
  },
  {
    text: (
      <>
        <div className="slide-text-header mb-12">How Joon helps kids</div>
        <div className="font-footnote">Set clear goals and expectations </div>
        <div className="font-footnote">Turn actions into routines</div>
      </>
    ),
    image: "/images/onboardTutorial/slide_kid1.svg",
    buttonText: "Next",
  },
  {
    text: (
      <>
        <div className="slide-text-header mb-12">How Joon helps kids</div>
        <div className="font-footnote">
          Provides engaging rewards and feedback
        </div>
        <div className="font-footnote">
          Encourages positive behavior with instant incentives
        </div>
      </>
    ),
    image: "/images/onboardTutorial/slide_kid2.svg",
    buttonText: "Finish",
  },
]

const HavePatientInMindView = () => {
  const navigate = useNavigate()
  const { onOpen: openAddPatientModal } = useAddPatientModalStore()
  const { onClose: closeOnboardTutorialModal } = useOnboardTutorialModal()
  const isMobile = useMediaQuery("(max-width:800px)")

  const onClickTryJoon = () => {
    navigate("/patients")
    openAddPatientModal()
    closeOnboardTutorialModal()
    localStorage.removeItem(localStorageItems.needsToSeeTutorial)
    trackAnalyticEvent(ANALYTIC_EVENTS.welcome_tutorial_add_patient)
  }

  const onClickMoreQuestions = () => {
    localStorage.removeItem(localStorageItems.needsToSeeTutorial)
    closeOnboardTutorialModal()
    navigate("/get-started")
    trackAnalyticEvent(ANALYTIC_EVENTS.welcome_tutorial_more_questions)
  }

  return (
    <FlexBox
      direction="column"
      justify="center"
      align="center"
      gap={SPACING.space6}
    >
      <Typography variant="h2" color={JoonUIColor.semantic.primary}>
        Have a client in mind?
      </Typography>
      <Typography textAlign="center" variant="body">
        When you invite your clients, they will get full free access to the Joon
        parent app.
      </Typography>
      <FlexBox gap={SPACING.space4}>
        <Button
          text="Try Joon with my clients"
          fullWidth
          onClick={onClickTryJoon}
        />
        {isMobile ? (
          <button
            onClick={onClickMoreQuestions}
            style={{ width: "100%", padding: SPACING.space2 }}
          >
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              I have more questions
            </Typography>
          </button>
        ) : (
          <button
            onClick={closeOnboardTutorialModal}
            style={{ width: "100%", padding: SPACING.space2 }}
          >
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Maybe later
            </Typography>
          </button>
        )}
      </FlexBox>
    </FlexBox>
  )
}
