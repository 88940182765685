import { useMemo } from "react"

import { usePracticeQuery } from "../../networking/queries"
import { SubscriptionLimitType } from "../../types"

const usePaywall = (subscriptionLimitType: SubscriptionLimitType) => {
  const { practice } = usePracticeQuery()

  const { remainingSlots, canAddToGroup, slotLimit } = useMemo(() => {
    const subscriptionLimit = practice?.subscription_limits.find(
      (limit) => limit.type === subscriptionLimitType
    )

    if (!subscriptionLimit) {
      return {
        remainingSlots: Infinity,
        canAddToGroup: true,
        slotLimit: Infinity,
      }
    } else {
      const remainingSlots =
        subscriptionLimit.max_value - subscriptionLimit.current_value
      return {
        remainingSlots: remainingSlots,
        canAddToGroup: remainingSlots > 0,
        slotLimit: subscriptionLimit?.max_value,
      }
    }
  }, [practice, subscriptionLimitType])

  return {
    remainingSlots,
    canAddToGroup,
    slotLimit,
  }
}

export default usePaywall
