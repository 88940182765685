import { setDeviceId } from "@amplitude/analytics-browser"
import { useEffect } from "react"
import { useLocation, useSearchParams } from "react-router-dom"

import { isVercelDeployment } from "../constants"
import { usePracticeQuery, useUserQuery } from "../networking/queries"
import {
  ANALYTIC_EVENTS,
  GA_EVENTS,
  analyticsUserDidLogin,
  trackAnalyticEvent,
  trackFBPixelEvent,
  trackGAEvent,
} from "../util/analytics"
import { localStorageItems } from "../util/storage"

export const useAnalytics = () => {
  const location = useLocation()
  const { user, userStatus } = useUserQuery()
  const { practice, practiceStatus } = usePracticeQuery()
  const [searchParams] = useSearchParams()
  const deviceId = searchParams.get("deviceId")

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // @ts-ignore
      const navType = event.currentTarget?.performance?.navigation?.type
      if (navType === 0) trackAnalyticEvent(ANALYTIC_EVENTS.page_close)
      else if (navType === 1) trackAnalyticEvent(ANALYTIC_EVENTS.page_refresh)
      else if (navType === 2)
        trackAnalyticEvent(ANALYTIC_EVENTS.page_back_forward)
    }

    // Add the event listener
    window.addEventListener("beforeunload", handleBeforeUnload)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    if (!deviceId) return
    setDeviceId(deviceId)
    // masking the url if user is not logged in
    // because otherwise it will already redirect
    if (!user)
      window.history.replaceState(null, "Joon - Welcome", location.pathname)
  }, [deviceId, user, location])

  useEffect(() => {
    if (import.meta.env.DEV || isVercelDeployment) return
    trackAnalyticEvent("page:shown", { page: location.pathname })
  }, [location.pathname])

  useEffect(() => {
    if (userStatus === "success" && practiceStatus === "success") {
      if (!user || !practice) return
      const parentId = user.id
      const practiceId = practice.id

      analyticsUserDidLogin(parentId, practiceId)
    }
  }, [practiceStatus, userStatus, user, practice])

  // track complet registration event after signup
  useEffect(() => {
    const needsToFireRegistrationEvent = !!localStorage.getItem(
      localStorageItems.needsToFireRegistrationEvent
    )
    if (!needsToFireRegistrationEvent || !user) return
    trackFBPixelEvent(
      "CompleteRegistration",
      {},
      { email: user.email, id: user.id }
    )
    trackGAEvent(GA_EVENTS.createAccount)
    localStorage.removeItem(localStorageItems.needsToFireRegistrationEvent)
  }, [user])
}
