import {
  EditIcon,
  FlexBox,
  JoonUIColor,
  Shadow,
  SPACING,
  TextButton,
  TrashIcon,
  Typography,
  UserRole,
} from "@joonapp/web-shared"
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table"
import dayjs from "dayjs"
import { useMemo, useState } from "react"

import { useDeleteClientModalStore } from "./DeleteClientModal"
import { useManageClientModalStore } from "./ManageClientModal"
import useAdminUser from "./useAdminUser"
import PageLoader from "../../components/loading/PageLoader"
import CustomTable from "../../components/table/CustomTable"
import TableHeaderCell from "../../components/table/TableHeaderCell"
import { usePatientGroupsQuery } from "../../networking/queries"
import { PatientGroup } from "../../types"

const columnHelper = createColumnHelper<PatientGroup>()

const ClientManagementTable = () => {
  const { data: patientGroups, isLoading: isLoadingClients } =
    usePatientGroupsQuery({ admin_view: true })

  const { onOpen: onOpenDeleteClientModal } = useDeleteClientModalStore()
  const { onOpen: onOpenManageClientModal } = useManageClientModalStore()

  const [sorting, setSorting] = useState([{ id: "Date added", desc: true }])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })
  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null)

  const tableData = useMemo(() => patientGroups || [], [patientGroups])
  const isAdmin = useAdminUser()

  const createColumns = () => [
    columnHelper.accessor(
      (row) =>
        row.profiles.find((p) => p.role === UserRole.PATIENT)?.user.name ||
        "N/A",
      {
        id: "Client name",
        cell: (info) => (
          <Typography variant="bodySmall">{info.getValue()}</Typography>
        ),
        header: () => <TableHeaderCell>Client name</TableHeaderCell>,
        size: 150,
      }
    ),
    columnHelper.accessor(
      (row) =>
        // @ts-ignore - includes parent name and login date
        row.profiles.find((p) => p.role === UserRole.PATIENT)?.user.parent
          ?.name || "N/A",
      {
        id: "Parent name",
        cell: (info) => (
          <Typography variant="bodySmall">{info.getValue()}</Typography>
        ),
        header: () => <TableHeaderCell>Parent name</TableHeaderCell>,
        size: 150,
      }
    ),
    columnHelper.accessor((row) => row.date_created, {
      id: "Date added",
      cell: (info) => (
        <Typography variant="bodySmall">
          {dayjs.unix(info.getValue()).format("MMM D, YYYY")}
        </Typography>
      ),
      header: () => <TableHeaderCell>Date Added</TableHeaderCell>,
      size: 200,
    }),
    columnHelper.accessor(
      (row) => {
        const clinicians = row.profiles
          .filter((p) => p.role === UserRole.THERAPIST)
          .map((p) => p.user.name)
          .join(", ")
        return clinicians || "N/A"
      },
      {
        id: "Clinician names",
        cell: (info) => (
          <Typography variant="bodySmall">
            {info.getValue() || "None"}
          </Typography>
        ),
        header: () => <TableHeaderCell>Clinicians</TableHeaderCell>,
        size: 200,
      }
    ),
    columnHelper.accessor((row) => row.id, {
      id: "Actions",
      header: () => <TableHeaderCell></TableHeaderCell>,
      cell: (info) => (
        <div
          style={{
            visibility: hoveredRowId === info.row.id ? "visible" : "hidden",
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <TextButton
            onClick={() => {
              console.log("info.row.original.id", info.row.original.id)
              onOpenManageClientModal(info.row.original.id)
            }}
          >
            <EditIcon size={16} color={JoonUIColor.icon.neutral} />
          </TextButton>
          {isAdmin && (
            <TextButton
              onClick={() => onOpenDeleteClientModal(info.row.original.id)}
            >
              <TrashIcon size={16} color={JoonUIColor.icon.neutral} />
            </TextButton>
          )}
        </div>
      ),
    }),
  ]

  const table = useReactTable({
    data: tableData,
    columns: createColumns(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    state: { sorting, pagination },
  })

  if (isLoadingClients) return <PageLoader />

  if (tableData.length === 0) {
    return <ClientManagementEmptyState />
  }

  return (
    <CustomTable
      table={table}
      hoveredRowId={hoveredRowId}
      setHoveredRowId={setHoveredRowId}
    />
  )
}

export default ClientManagementTable

const ClientManagementEmptyState = () => {
  return (
    <FlexBox align="center" justify="center" style={{ height: "100px" }}>
      <Typography
        variant="bodySmall"
        style={{
          border: `1px solid ${JoonUIColor.border.default}`,
          borderRadius: SPACING.space2,
          padding: `${SPACING.space4} ${SPACING.space6}`,
          width: "fit-content",
          margin: SPACING.space2,
          boxShadow: Shadow.high,
        }}
      >
        No clients added yet.
      </Typography>
    </FlexBox>
  )
}
