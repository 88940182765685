import { FlexBox } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { Outlet, Route, Routes } from "react-router-dom"

import { MobileSidebar } from "./components/nav/MobileSidebar"
import Navbar from "./components/nav/Navbar"
import { useAnalytics } from "./hooks/useAnalytics"
import { usePracticeQuery, useUserQuery } from "./networking/queries"
import AuthPage from "./pages/auth"
import GetStarted from "./pages/getStarted"
import GetFamilyStarted from "./pages/getStarted/GetFamilyStarted"
import HowJoonWorks from "./pages/getStarted/HowJoonWorks"
import TestApps from "./pages/getStarted/TestApps"
import Onboarding from "./pages/onboarding"
import WeeklyProgress from "./pages/patientInfo/behaviorsIncidents/BehaviorsIncidentsPage"
import HomeRoutinesPage from "./pages/patientInfo/homeRoutines/HomeRoutinesPage"
import DataTasksTab from "./pages/patientInfo/parentTraining/dataTasks/DataTasksTab"
import ModulesTab from "./pages/patientInfo/parentTraining/modules/ModulesTab"
import ParentTrainingPage from "./pages/patientInfo/parentTraining/ParentTrainingPage"
import ParentConnect from "./pages/patientInfo/parentTraining/ParentTrainingsPage"
import TodoTasksTab from "./pages/patientInfo/parentTraining/todoTasks/TodoTasksTab"
import ParentTrainingSummary from "./pages/patientInfo/parentTraining/TrainingSummaryTab"
import PatientDetails from "./pages/patientInfo/patientDetails/PatientDetails"
import PatientInfo from "./pages/patientInfo/PatientInfo"
import PatientsDashboard from "./pages/patientsDashboard/PatientsDashboard"
import Resources from "./pages/resources/Resources"
import Security from "./pages/security"
import HIPPACompliance from "./pages/security/HIPPACompliance"
import PIIPolicy from "./pages/security/PIIPolicy"
import PrivacyPolicy from "./pages/security/PrivacyPolicy"
import Settings from "./pages/settings/Settings"
import { ParentTrainingTab, PatientInfoTab } from "./types"
import {
  useExperimentsQuery,
  useOnboardingExperiments,
} from "./util/experimentManager"

export const Routing = () => {
  const { user } = useUserQuery()
  const { practice } = usePracticeQuery()
  useAnalytics()
  useExperimentsQuery()
  useOnboardingExperiments()

  const isLoggedIn = !!user
  const hasClinic = !!practice
  const isMobile = useMediaQuery("(max-width: 800px)")

  if (isLoggedIn && !hasClinic) return <></>

  const Layout = () => (
    <FlexBox
      direction="column"
      wrap={false}
      style={{
        height: "100vh",
        overflowX: "hidden",
        overflowY: isMobile ? "auto" : "hidden",
        width: "100vw",
      }}
    >
      {isLoggedIn && <Navbar />}
      <MobileSidebar />
      <FlexBox
        wrap={false}
        style={{ flexGrow: 1, overflow: isMobile ? "visible" : "auto" }}
      >
        <Outlet />
      </FlexBox>
    </FlexBox>
  )

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<PatientsDashboard />} />
        <Route path="/onboard" element={<Onboarding />} />
        <Route path="/auth/:type" element={<AuthPage />} />

        {/* Patient Info */}
        <Route path="/patients" element={<PatientsDashboard />} />
        <Route path="/patients/:userId" element={<PatientInfo />}>
          <Route path={PatientInfoTab.BEHAVIORS} element={<WeeklyProgress />} />
          <Route
            path={PatientInfoTab.PARENT_TRAINING}
            element={<ParentConnect />}
          />

          <Route
            path={PatientInfoTab.PATIENT_DETAILS}
            element={<PatientDetails />}
          />
          <Route
            path={PatientInfoTab.HOME_ROUTINES}
            element={<HomeRoutinesPage />}
          />
          <Route
            path={`/patients/:userId/${PatientInfoTab.PARENT_TRAINING}/:trainingId`}
            element={<ParentTrainingPage />}
          >
            <Route
              path={ParentTrainingTab.SUMMARY}
              element={<ParentTrainingSummary />}
            />
            <Route path={ParentTrainingTab.MODULES} element={<ModulesTab />} />
            <Route
              path={ParentTrainingTab.DATA_TASKS}
              element={<DataTasksTab />}
            />
            <Route
              path={ParentTrainingTab.TODO_TASKS}
              element={<TodoTasksTab />}
            />
          </Route>
        </Route>

        {/* Get Started */}
        <Route path="/get-started" element={<GetStarted />} />
        <Route path="/get-started/how-joon-works" element={<HowJoonWorks />} />
        <Route path="/get-started/test-apps" element={<TestApps />} />
        <Route path="/get-started/families" element={<GetFamilyStarted />} />

        <Route path="/resources" element={<Resources />} />
        {/* Security & Privacy */}
        <Route path="/security" element={<Security />} />
        <Route path="/security/hipaa" element={<HIPPACompliance />} />
        <Route path="/security/privacy" element={<PrivacyPolicy />} />
        <Route path="/security/pii" element={<PIIPolicy />} />
        <Route path="/settings" element={<Settings />} />
      </Route>
    </Routes>
  )
}
