import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

import ModalProvider from "./components/modals/ModalProvider"
import { CustomToaster } from "./components/toast/Toast"
import { isVercelDeployment } from "./constants"
import { queryClient } from "./queryClient"
import { Routing } from "./routing"
import { ThemeProvider } from "./util/theme"
import "./pdfConfig"

import "@joonapp/web-shared/dist/style.css"
import "react-loading-skeleton/dist/skeleton.css"

function App() {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <QueryClientProvider client={queryClient}>
          <Routing />
          <CustomToaster />
          <ModalProvider />
          {import.meta.env.DEV && !isVercelDeployment && <ReactQueryDevtools />}
        </QueryClientProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
