import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"

import { JoonColor } from "../../util/theme"

const BackButton = () => {
  const navigate = useNavigate()
  return (
    <Button
      onClick={() => navigate(-1)}
      style={{
        background: JoonColor.blue,
        borderRadius: "16px",
        color: "white",
        padding: "5px 16px",
        width: "fit-content",
      }}
    >
      <ArrowBackIcon style={{ marginRight: "8px", fontSize: "16px" }} />
      Back
    </Button>
  )
}

export default BackButton
