import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextButton,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useMutation } from "@tanstack/react-query"
import { useState } from "react"
import { create } from "zustand"

import usePaywall from "./usePaywall"
import { usePracticeQuery } from "../../networking/queries"
import { requestSubscriptionLimitIncrease } from "../../networking/user"
import { SubscriptionLimitType, ToastType } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { createToast } from "../toast/Toast"

interface UpgradePlanModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useUpgradePlanModalStore = create<UpgradePlanModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  })
)

const UpgradePlanModal = () => {
  const { isOpen, onClose } = useUpgradePlanModalStore()
  const [patientSlots, setPatientSlots] = useState("")
  const [teamMemberSlots, setTeamMemberSlots] = useState("")
  const { practice } = usePracticeQuery()

  const upgradePlanMutation = useMutation(requestSubscriptionLimitIncrease)

  const { remainingSlots: remainingPatientSlots, slotLimit: patientSlotLimit } =
    usePaywall(SubscriptionLimitType.NUM_LINKED_PATIENTS)
  const {
    remainingSlots: remainingPracticeSlots,
    slotLimit: practiceSlotLimit,
  } = usePaywall(SubscriptionLimitType.NUM_CLINICIANS)

  const handleChangePatients = (e: any) => {
    const newValue = e.target.value
    if (!isNaN(newValue) || newValue === "") setPatientSlots(newValue)
  }

  const handleChangeTeamMember = (e: any) => {
    const newValue = e.target.value
    if (!isNaN(newValue) || newValue === "") setTeamMemberSlots(newValue)
  }

  const onClickUpgradePlan = async () => {
    if (!practice) return
    if (upgradePlanMutation.isLoading) return
    if (patientSlots === "" && teamMemberSlots === "") return

    const requests = []
    if (patientSlots !== "")
      requests.push({
        type: SubscriptionLimitType.NUM_LINKED_PATIENTS,
        requestedValue: parseInt(patientSlots),
      })
    if (teamMemberSlots !== "")
      requests.push({
        type: SubscriptionLimitType.NUM_CLINICIANS,
        requestedValue: parseInt(teamMemberSlots),
      })

    try {
      await upgradePlanMutation.mutateAsync({
        practiceId: practice.id,
        requests,
      })
      onClose()
      createToast({
        title: "Plan upgrade request sent.",
        type: ToastType.SUCCESS,
      })
      trackAnalyticEvent(ANALYTIC_EVENTS.send_upgrade_plan_request, {
        remainingPatientSlots,
        remainingPracticeSlots,
      })
    } catch (error) {}
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader
        onClose={onClose}
        title="Upgrade plan request"
        showBorderBottom
      />
      <FlexBox
        direction="column"
        style={{ padding: SPACING.space6, paddingTop: SPACING.space4 }}
        gap={SPACING.space4}
      >
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="bodyBold" textAlign="left">
            Current plan:
          </Typography>
          {patientSlotLimit !== Infinity && (
            <Typography variant="body" textAlign="left">
              {patientSlotLimit} client slots ({remainingPatientSlots}{" "}
              remaining)
            </Typography>
          )}
          {practiceSlotLimit !== Infinity && (
            <Typography variant="body" textAlign="left">
              {practiceSlotLimit} team member slots ({remainingPracticeSlots}{" "}
              remaining)
            </Typography>
          )}
        </FlexBox>

        {remainingPatientSlots !== Infinity && (
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="bodyBold">
              How many client slots do you need?
            </Typography>
            <TextInput
              name="client slots"
              placeholder="eg. 20"
              value={patientSlots}
              maxLength={5}
              onChange={handleChangePatients}
            />
          </FlexBox>
        )}
        {remainingPracticeSlots !== Infinity && (
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="bodyBold">
              How many team member slots do you need?
            </Typography>
            <TextInput
              name="team member slots"
              placeholder="eg. 10"
              value={teamMemberSlots}
              maxLength={5}
              onChange={handleChangeTeamMember}
            />
          </FlexBox>
        )}
        <FlexBox direction="row" justify="flex-end" gap={SPACING.space4}>
          <TextButton onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </TextButton>
          <Button
            text="Request plan upgrade"
            onClick={onClickUpgradePlan}
            isLoading={upgradePlanMutation.isLoading}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default UpgradePlanModal
