import { useEffect, useMemo } from "react"

import UpgradePlanButton from "./UpgradePlanButton"
import usePaywall from "./usePaywall"
import { SubscriptionLimitType } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { Reminder } from "../reminder/Reminder"

const REMAINING_PRACTICE_NOTIFICATION_THRESHOLD = 3

const PracticePaywallReminder = ({
  buttonType = "custom",
  additionalText = "",
}: {
  buttonType?: "primary" | "custom"
  additionalText?: string
}) => {
  const { remainingSlots: remainingPracticeSlots } = usePaywall(
    SubscriptionLimitType.NUM_CLINICIANS
  )

  const reminderText = useMemo(() => {
    if (remainingPracticeSlots <= 0) {
      return `You don't have any more slots for new members. ${additionalText}`
    } else {
      return `You have ${remainingPracticeSlots} clinician slot${
        remainingPracticeSlots > 1 ? "s" : ""
      } left in your current plan.`
    }
  }, [remainingPracticeSlots, additionalText])

  useEffect(() => {
    if (
      remainingPracticeSlots === null ||
      remainingPracticeSlots > REMAINING_PRACTICE_NOTIFICATION_THRESHOLD
    )
      return
    trackAnalyticEvent(ANALYTIC_EVENTS.display_paywall_reminder, {
      type: SubscriptionLimitType.NUM_LINKED_PATIENTS,
      remainingSlots: remainingPracticeSlots,
    })
  }, [remainingPracticeSlots])

  if (
    remainingPracticeSlots === null ||
    remainingPracticeSlots > REMAINING_PRACTICE_NOTIFICATION_THRESHOLD
  )
    return null

  return (
    <Reminder
      text={reminderText}
      type={remainingPracticeSlots <= 0 ? "alert" : "warning"}
      button={<UpgradePlanButton buttonType={buttonType} />}
    />
  )
}

export default PracticePaywallReminder
