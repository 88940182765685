import {
  JoonColorExpanded,
  JoonUIColor,
  QuestRoutine,
  UserRole,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

import {
  NumberOfClinicians,
  ParentTrainingStatus,
  TargetBehaviorStatus,
} from "./types"

export const isVercelDeployment =
  window.location.hostname.endsWith(".vercel.app")

export const REPETITIONS = {
  daily: [true, true, true, true, true, true, true],
  noDays: [false, false, false, false, false, false, false],
  weekdays: [false, true, true, true, true, true, false],
  weekends: [true, false, false, false, false, false, true],
}

export const SKILL_MAPPING = {
  knowledge: "Learning & Education",
  stamina: "Mental Health",
  strength: "Health & Fitness",
  kindness: "Relationship Building",
  focus: "Focus & Self Control",
  teamwork: "Chores & Housework",
} as { [key: string]: string }

export const SKILL_DROPDOWN = Object.entries(SKILL_MAPPING).map(
  ([key, value]) => ({
    label: value,
    value: key,
  })
)

export const creatorDropdownOptions = [
  { label: "Parent", value: UserRole.PARENT },
  { label: "Therapist", value: UserRole.THERAPIST },
  { label: "Unknown", value: "none" },
]

export const timeOfDayDropdownOptions = [
  { label: "Morning", value: QuestRoutine.MORNING },
  { label: "Afternoon", value: QuestRoutine.AFTERNOON },
  { label: "Night", value: QuestRoutine.NIGHT },
  { label: "Anytime", value: QuestRoutine.ANYTIME },
]

export const categoryDropdownOptions = [
  ...SKILL_DROPDOWN,
  { label: "None", value: null },
]

export const targetFrequencyDrodownOptions = [
  { label: "1x", value: 1 },
  { label: "2x", value: 2 },
  { label: "3x", value: 3 },
  { label: "4x", value: 4 },
  { label: "5x", value: 5 },
  { label: "6x", value: 6 },
  { label: "7x", value: 7 },
]

export const numClinicansDropdownOptions = Object.entries(
  NumberOfClinicians
).map(([key, val]) => ({
  value: key,
  label: val,
}))

export const DATE_RANGES = {
  past7Days: {
    startDate: dayjs().subtract(7, "day"),
    endDate: dayjs(),
    label: "Past 7 Days",
    value: "past7Days",
  },
  past14Days: {
    startDate: dayjs().subtract(14, "day"),
    endDate: dayjs(),
    label: "Past 14 Days",
    value: "past14Days",
  },
  previous7Days: {
    startDate: dayjs().subtract(14, "day"),
    endDate: dayjs().subtract(7, "day"),
    label: "Previous 7 Days",
    value: "previous7Days",
  },
  allTimeDates: {
    startDate: dayjs("2021-01-01"),
    endDate: dayjs(),
    label: "All Time",
    value: "allTime",
  },
  thisWeek: {
    startDate: dayjs().startOf("week"),
    endDate: dayjs().endOf("week"),
    label: "This Week",
    value: "thisWeek",
  },
  lastWeek: {
    startDate: dayjs().subtract(1, "week").startOf("week"),
    endDate: dayjs().subtract(1, "week").endOf("week"),
    label: "Last Week",
    value: "lastWeek",
  },
  past4Weeks: {
    startDate: dayjs().subtract(3, "week"),
    endDate: dayjs(),
    label: "Past 4 Weeks",
    value: "past4Weeks",
  },
  past6Weeks: {
    startDate: dayjs().subtract(5, "week"),
    endDate: dayjs(),
    label: "Past 6 Weeks",
    value: "past6Weeks",
  },
  past8Weeks: {
    startDate: dayjs().subtract(8, "week"),
    endDate: dayjs(),
    label: "Past 8 Weeks",
    value: "past8Weeks",
  },
  past12Weeks: {
    startDate: dayjs().subtract(12, "week"),
    endDate: dayjs(),
    label: "Past 12 Weeks",
    value: "past12Weeks",
  },
  past3Months: {
    startDate: dayjs().subtract(3, "month"),
    endDate: dayjs(),
    label: "Past 3 Months",
    value: "past3Months",
  },
  past6Months: {
    startDate: dayjs().subtract(6, "month"),
    endDate: dayjs(),
    label: "Past 6 Months",
    value: "past6Months",
  },
  past9Months: {
    startDate: dayjs().subtract(9, "month"),
    endDate: dayjs(),
    label: "Past 9 Months",
    value: "past9Months",
  },
}

export const TargetBehaviorStatusColors = {
  [TargetBehaviorStatus.BASELINING]: {
    primary: JoonUIColor.text.disabled,
    secondary: JoonUIColor.background.lightGray,
  },
  [TargetBehaviorStatus.STABLE]: {
    primary: JoonUIColor.semantic.info,
    secondary: JoonColorExpanded.blue100,
  },
  [TargetBehaviorStatus.IMPROVING]: {
    primary: JoonUIColor.semantic.success,
    secondary: JoonColorExpanded.viridian100,
  },
  [TargetBehaviorStatus.REGRESSING]: {
    primary: JoonColorExpanded.orange400,
    secondary: JoonColorExpanded.orange100,
  },
  [TargetBehaviorStatus.MASTERED]: {
    primary: JoonColorExpanded.indigo400,
    secondary: JoonColorExpanded.indigo100,
  },
} as const

export const ParentTrainingStatusColors = {
  [ParentTrainingStatus.INACTIVE]: {
    primary: JoonUIColor.text.secondary,
    secondary: JoonColorExpanded.yellow200,
    bar: JoonColorExpanded.yellow200,
  },
  [ParentTrainingStatus.IN_PROGRESS]: {
    primary: JoonUIColor.semantic.success,
    secondary: JoonColorExpanded.viridian100,
    bar: JoonUIColor.semantic.success,
  },
  [ParentTrainingStatus.MASTERED]: {
    primary: JoonColorExpanded.indigo400,
    secondary: JoonColorExpanded.indigo100,
    bar: JoonColorExpanded.indigo400,
  },
} as const

export const ParentTrainingStatusText = {
  [ParentTrainingStatus.INACTIVE]: "Draft",
  [ParentTrainingStatus.IN_PROGRESS]: "Active",
  [ParentTrainingStatus.MASTERED]: "Mastered",
} as const

export const PAGE_CONTENT_WIDTH = "min(1200px, 100%)"

export const levelTagColors = {
  0: {
    primary: JoonUIColor.semantic.success,
    secondary: JoonColorExpanded.viridian100,
  },
  1: {
    primary: JoonColorExpanded.viridian500,
    secondary: JoonColorExpanded.viridian100,
  },
  2: {
    primary: JoonColorExpanded.viridian500,
    secondary: JoonColorExpanded.viridian100,
  },
  3: {
    primary: JoonColorExpanded.viridian500,
    secondary: JoonColorExpanded.yellow100,
  },
  4: {
    primary: JoonUIColor.semantic.warning,
    secondary: JoonColorExpanded.orange100,
  },
  5: {
    primary: JoonUIColor.semantic.warning,
    secondary: JoonColorExpanded.orange100,
  },
  6: {
    primary: JoonUIColor.semantic.destructive,
    secondary: JoonColorExpanded.red100,
  },
}

export const intensityLevelText = {
  0: "Extremely Low",
  1: "Low",
  2: "Somewhat low",
  3: "Moderate",
  4: "Somewhat high",
  5: "High",
  6: "Extremely High",
}

export const responseLevelText = {
  0: "Very well",
  1: "Better than expected",
  2: "A little better than expected",
  3: "As expected",
  4: "Could be better",
  5: "Could be much better",
  6: "Poorly",
}
