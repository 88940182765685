import { FlexBox, JoonColorExpanded } from "@joonapp/web-shared"
import { useParams } from "react-router-dom"

import ForgotPassword from "./ForgotPassword"
import SignIn from "./SignIn"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import OnboardBox from "../onboarding/OnboardBox"

function AuthSection() {
  const { type: authType } = useParams()

  return (
    <PageWrapper
      style={{
        background: `linear-gradient(180deg, rgba(255, 255, 255, 0.80) 2.83%, rgba(255, 255, 255, 0.00) 51.53%, rgba(255, 255, 255, 0.80) 99.81%), ${JoonColorExpanded.indigo100}`,
      }}
    >
      <FlexBox align="center" justify="center" style={{ height: "80vh" }}>
        <OnboardBox>
          {authType === "forgotpass" ? <ForgotPassword /> : <SignIn />}
        </OnboardBox>
      </FlexBox>
    </PageWrapper>
  )
}

export default AuthSection
