import { useEffect, useMemo } from "react"

import UpgradePlanButton from "./UpgradePlanButton"
import usePaywall from "./usePaywall"
import { SubscriptionLimitType } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { Reminder } from "../reminder/Reminder"

const REMAINING_PATIENT_NOTIFICATION_THRESHOLD = 1

const PatientPaywallReminder = ({
  buttonType = "custom",
  additionalText = "",
}: {
  buttonType?: "primary" | "custom"
  additionalText?: string
}) => {
  const { remainingSlots: remainingPatientSlots } = usePaywall(
    SubscriptionLimitType.NUM_LINKED_PATIENTS
  )

  const reminderText = useMemo(() => {
    if (remainingPatientSlots <= 0) {
      return `You don't have any more slots for new clients. ${additionalText}`
    } else {
      return `You have ${remainingPatientSlots} client slot${
        remainingPatientSlots > 1 ? "s" : ""
      } left in your current plan.`
    }
  }, [remainingPatientSlots, additionalText])

  useEffect(() => {
    if (
      remainingPatientSlots === null ||
      remainingPatientSlots > REMAINING_PATIENT_NOTIFICATION_THRESHOLD
    )
      return
    trackAnalyticEvent(ANALYTIC_EVENTS.display_paywall_reminder, {
      type: SubscriptionLimitType.NUM_LINKED_PATIENTS,
      remainingSlots: remainingPatientSlots,
    })
  }, [remainingPatientSlots])

  if (
    remainingPatientSlots === null ||
    remainingPatientSlots > REMAINING_PATIENT_NOTIFICATION_THRESHOLD
  )
    return null

  return (
    <Reminder
      text={reminderText}
      type={remainingPatientSlots <= 0 ? "alert" : "warning"}
      button={<UpgradePlanButton buttonType={buttonType} />}
      style={{ flexWrap: "wrap" }}
    />
  )
}

export default PatientPaywallReminder
