import {
  Button,
  EditIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import AddEditMasteryCriteriaModal from "./AddEditMasteryCriteriaModal"
import AddEditObservationalDataModal from "./AddEditObservationalDataModal"
import AddMasteryCriteriaButton from "./AddMasteryCriteriaButton"
import DeleteObservationalDataModal from "./DeleteObservationalDataModal"
import ObservationalDataGraph from "./ObservationalDataGraph"
import ObservationalDataTable from "./ObservationalDataTable"
import useAddEditMasteryCriteriaModalStore from "./store/useAddEditMasteryCriteriaModalStore"
import useAddEditObservationalDataModalStore from "./store/useAddEditObservationalDataModalStore"
import DataViewSegmentedControl from "../../../../components/dataViewSegmentedControl/DataViewSegmentedControl"
import CustomTooltip from "../../../../components/tooltip/CustomTooltip"
import { useCurrentTrainingQuery } from "../../../../networking/queries"
import { DataSCView } from "../../../../types"

const ObservationalDataSection = () => {
  const [viewType, setViewType] = useState<DataSCView>(DataSCView.GRAPH)

  const { onOpen: onOpenAddEditObservationalDataModal } =
    useAddEditObservationalDataModalStore()
  const { onOpen: onOpenAddEditMasterCriteriaModal } =
    useAddEditMasteryCriteriaModalStore()

  const { data: currentTraining } = useCurrentTrainingQuery()

  if (!currentTraining) return null

  return (
    <FlexBox direction="column" gap={SPACING.space2}>
      <FlexBox
        direction="row"
        justify="space-between"
        gap={SPACING.space6}
        wrap={false}
        align="center"
      >
        <Typography variant="bodyBold">
          Observation data tracking towards mastery criteria
        </Typography>
        <FlexBox
          direction="row"
          gap={SPACING.space2}
          wrap={false}
          align="center"
          style={{ width: "fit-content" }}
        >
          <DataViewSegmentedControl view={viewType} setView={setViewType} />
          {!currentTraining.mastery_criteria_measurement && (
            <CustomTooltip
              id="add-data-tooltip"
              children={
                <Typography
                  variant="bodySmall"
                  color={JoonUIColor.text.inverted}
                >
                  Set a mastery criteria to add data
                </Typography>
              }
            />
          )}
          <Button
            buttonType="secondary"
            onClick={() => onOpenAddEditObservationalDataModal()}
            text="+ Add data"
            style={{ whiteSpace: "nowrap" }}
            disabled={!currentTraining.mastery_criteria_measurement}
            data-tooltip-id="add-data-tooltip"
          />
        </FlexBox>
      </FlexBox>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{
          border: `1px solid ${JoonUIColor.border.default}`,
          borderRadius: SPACING.space2,
          padding: SPACING.space4,
          background: JoonUIColor.background.primaryNeutral,
        }}
      >
        <FlexBox
          direction="row"
          align="center"
          wrap={false}
          justify="space-between"
          style={{
            borderBottom: `1px solid ${JoonUIColor.border.default}`,
            paddingBottom: SPACING.space4,
          }}
        >
          {currentTraining?.mastery_criteria ? (
            <Typography variant="bodyBold">
              {currentTraining?.mastery_criteria}
            </Typography>
          ) : (
            <Typography variant="body" color={JoonUIColor.text.secondary}>
              No mastery criteria set yet!
            </Typography>
          )}
          <TextButton
            onClick={() => onOpenAddEditMasterCriteriaModal(currentTraining)}
          >
            <EditIcon size={16} color={JoonUIColor.icon.neutral} />
          </TextButton>
        </FlexBox>
        <FlexBox
          justify="center"
          align="center"
          style={{ position: "relative" }}
        >
          {viewType === DataSCView.GRAPH ? (
            <ObservationalDataGraph />
          ) : (
            <ObservationalDataTable />
          )}
          <AddMasteryCriteriaButton />
        </FlexBox>
      </FlexBox>
      <AddEditObservationalDataModal />
      <AddEditMasteryCriteriaModal />
      <DeleteObservationalDataModal />
    </FlexBox>
  )
}

export default ObservationalDataSection
