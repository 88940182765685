import PDFPreview from "./PDFPreview"
import BackButton from "../../components/buttons/BackButton"
import { requireAuth } from "../../util/auth"

const GetFamilyStarted = requireAuth(() => {
  return (
    <div className="page-wrapper">
      <BackButton />
      <div className="page-title">Getting a Family Started with Joon</div>
      <div className="help-section">
        <div className="help-card">
          <div className="help-card-title">Starter Guide for You</div>
          <div className="mb-6">
            View your Clinician Starter Pack PDF that includes all the necessary
            information for you to get familiarized with Joon and help refer
            families to the app.
          </div>
          <PDFPreview
            pdfUrl="/images/getStarted/therapist_guide.pdf"
            previewUrl="/images/getStarted/preview_therapist_guide.png"
          />
        </div>
        <div className="help-card">
          <div className="help-card-title">
            Printable Share Sheet for Families
          </div>
          <div className="mb-6">
            Download and print the PDF below to hand out to families in person
            during a session. The PDF includes all the necessary information for
            a parent to learn about Joon and get started with the app at home.
          </div>
          <PDFPreview
            pdfUrl="/images/getStarted/family_guide.pdf"
            previewUrl="/images/getStarted/preview_family_guide.png"
          />
        </div>
      </div>
    </div>
  )
})

export default GetFamilyStarted
