import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { ParentTrainingTab, PatientInfoTab } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

const tabs = [
  { label: "Summary", value: ParentTrainingTab.SUMMARY },
  { label: "Learning modules", value: ParentTrainingTab.MODULES },
  { label: "Implementation tasks", value: ParentTrainingTab.DATA_TASKS },
  { label: "Quick to-dos", value: ParentTrainingTab.TODO_TASKS },
]

const ParentTrainingTabNav = () => {
  return (
    <FlexBox
      wrap={false}
      gap={SPACING.space2}
      style={{
        maxWidth: "100vw",
        overflow: "auto",
        scrollbarWidth: "thin",
      }}
    >
      {tabs.map((tab, i) => (
        <ParentTrainingNavButton tab={tab} key={i} />
      ))}
    </FlexBox>
  )
}

const ParentTrainingNavButton = ({ tab }: { tab: (typeof tabs)[number] }) => {
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()

  const isActive = location.pathname.includes(tab.value)

  const onClick = () => {
    if (!isActive) {
      navigate(
        `/patients/${params.userId}/${PatientInfoTab.PARENT_TRAINING}/${params.trainingId}/${tab.value}`
      )
      trackAnalyticEvent(ANALYTIC_EVENTS.patient_info_tab_click, { tab })
    }
  }

  return (
    <button onClick={onClick} style={{ padding: "0" }}>
      <FlexBox
        style={{
          padding: `${SPACING.space0} ${SPACING.space2} ${SPACING.space2} ${SPACING.space2}`,
          borderBottom: isActive
            ? `4px solid ${JoonUIColor.border.accent}`
            : "4px solid transparent",
          width: "fit-content",
          cursor: "pointer",
          whiteSpace: "nowrap",
          position: "relative",
        }}
        fullWidth
      >
        <Typography
          variant="caption"
          color={
            isActive ? JoonUIColor.text.primary : JoonUIColor.text.secondary
          }
        >
          {tab.label}
        </Typography>
      </FlexBox>
    </button>
  )
}

export default ParentTrainingTabNav
