import {
  ArrowRightIcon,
  Button,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import { useUpgradePlanModalStore } from "./UpgradePlanModal"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

const UpgradePlanButton = ({
  buttonType,
}: {
  buttonType: "primary" | "custom"
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const { onOpen } = useUpgradePlanModalStore()

  const onClickUpgrade = () => {
    onOpen()
    trackAnalyticEvent(ANALYTIC_EVENTS.open_upgrade_plan_request)
  }

  if (buttonType === "primary")
    return (
      <Button
        text="Upgrade plan"
        onClick={onClickUpgrade}
        size="small"
        style={{ whiteSpace: "nowrap" }}
      />
    )

  return (
    <button
      style={{
        background: JoonUIColor.background.primaryNeutral,
        borderRadius: "100px",
        padding: `0 ${SPACING.space2}`,
        marginLeft: SPACING.space2,
        border: isHovered
          ? `1px solid ${JoonUIColor.border.accent}`
          : "1px solid transparent",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        gap: SPACING.space2,
      }}
      onClick={onClickUpgrade}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Typography
        variant="caption"
        color={JoonUIColor.text.primaryAccent}
        style={{ whiteSpace: "nowrap" }}
      >
        Upgrade plan
      </Typography>
      <ArrowRightIcon color={JoonUIColor.text.primaryAccent} size={14} />
    </button>
  )
}

export default UpgradePlanButton
