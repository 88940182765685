import { Button, FlexBox, SPACING } from "@joonapp/web-shared"
import { create } from "zustand"

import CustomModal from "./CustomModal"
import { localStorageItems } from "../../util/storage"

interface ExampleBackstoryModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useExampleBackstoryModal = create<ExampleBackstoryModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => {
      localStorage.removeItem(localStorageItems.needsToViewDummyData)
      set({ isOpen: false })
    },
  })
)

const ExampleBackstoryModal = () => {
  const { isOpen, onClose } = useExampleBackstoryModal()

  return (
    <CustomModal isOpen={isOpen} handleClose={onClose}>
      <FlexBox
        direction="column"
        gap={SPACING.space6}
        style={{
          padding: SPACING.space6,
          height: "fit-content",
          width: "min(400px, 95vw)",
        }}
      >
        <div className="example-backstory-title">Meet Kevin</div>
        <div>
          <div className="example-backstory-text mb-18">
            <span className="bold">Kevin, 9, with ADHD, </span>
            faces difficulties with daily tasks like completing reading homework
            which often leads to arguments with caregivers.
          </div>
          <div className="example-backstory-text mb-18">
            Previous strategies like behavior charts have not helped due to
            inconsistent monitoring and failure to provide rewards.
          </div>
          <div className="example-backstory-text">
            Joon simplifies this by automating monitoring and making it easier
            for Kevin's caregiver to acknowledge when Kevin has achieved his
            goals. Since then, Kevin consistently completes his homework which
            has reduced arguments.
          </div>
        </div>
        <Button text="Check out Kevin's Progress" fullWidth onClick={onClose} />
      </FlexBox>
    </CustomModal>
  )
}

export default ExampleBackstoryModal
