import {
  AuthCode,
  AuthCodeType,
  Button,
  FlexBox,
  JoonUIColor,
  Shadow,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"

import ClientManagementTable from "./ClientManagementTable"
import DeleteClientModal from "./DeleteClientModal"
import ManageClientModal from "./ManageClientModal"
import useAdminUser from "./useAdminUser"
import { useAddPatientModalStore } from "../../components/modals/AddPatientModal"
import usePaywall from "../../components/paywall/usePaywall"
import {
  useAuthCodeQuery,
  usePatientGroupsQuery,
} from "../../networking/queries"
import { SubscriptionLimitType } from "../../types"

const ClientManagementSection = () => {
  const { canAddToGroup: canAddToPatientGroup } = usePaywall(
    SubscriptionLimitType.NUM_LINKED_PATIENTS
  )
  const isAdmin = useAdminUser()
  const { data: patientGroups } = usePatientGroupsQuery({ admin_view: isAdmin })

  const { onOpen: openAddPatientModal } = useAddPatientModalStore()

  const { data: authCode } = useAuthCodeQuery(AuthCodeType.ADMIT_PATIENT)
  const isMobile = useMediaQuery("(max-width: 800px)")

  const userIsAdmin = useAdminUser()

  return (
    <FlexBox direction="column" gap={SPACING.space4}>
      <FlexBox
        direction={isMobile ? "column" : "row"}
        wrap={false}
        justify="space-between"
        align={isMobile ? "flex-start" : "center"}
        gap={SPACING.space2}
      >
        <Typography
          variant="h3"
          style={{ whiteSpace: "nowrap" }}
          textAlign="left"
        >
          {userIsAdmin ? "Client Management" : "Your Clients"}
          {patientGroups && ` (${patientGroups?.length})`}
        </Typography>
        <FlexBox
          direction="row"
          wrap={false}
          style={{ width: "fit-content" }}
          gap={SPACING.space2}
        >
          {canAddToPatientGroup && (
            <FlexBox
              align="center"
              wrap={false}
              style={{
                border: `1px solid ${JoonUIColor.border.default}`,
                width: "fit-content",
                padding: `${SPACING.space2} ${SPACING.space4}`,
                borderRadius: SPACING.space8,
                boxShadow: Shadow.sunken,
                background: JoonUIColor.background.lightGray,
              }}
            >
              <Typography variant="caption" style={{ whiteSpace: "nowrap" }}>
                Client Invite Code:
              </Typography>
              <AuthCode code={authCode?.code || ""} />
            </FlexBox>
          )}
          <Button
            text="Invite client"
            onClick={openAddPatientModal}
            style={{ whiteSpace: "nowrap" }}
          />
        </FlexBox>
      </FlexBox>
      <div
        style={{
          padding: SPACING.space4,
          borderRadius: SPACING.space4,
          background: JoonUIColor.background.primaryNeutral,
          width: "100%",
          border: `1px solid ${JoonUIColor.border.default}`,
        }}
      >
        <ClientManagementTable />
      </div>
      <ManageClientModal />
      <DeleteClientModal />
    </FlexBox>
  )
}

export default ClientManagementSection
