import { AuthCodeType, UserRole } from "@joonapp/web-shared"

import {
  FormInfoFields,
  PatientGroup,
  Practice,
  SubscriptionLimitType,
  UserProfileSettingsInfo,
} from "../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../util/analytics"
import { createJoonAPIClient } from "../util/joon-api"
import { createErrorFromResponse } from "../util/util"

export const createPractice = async (formData: FormInfoFields) => {
  const { profession, clinicName } = formData

  try {
    const API = createJoonAPIClient()

    // Create  practice -----
    await API.post("api/practices/", {
      name: clinicName,
      nickname: "Therapist",
      profession,
    })
  } catch (error: any) {
    const errorMessage = createErrorFromResponse(error)
    if (error) throw new Error(errorMessage)
  }
}

export const joinPractice = async (
  formData: FormInfoFields,
  clinicCode: string
) => {
  const { profession } = formData

  try {
    const API = createJoonAPIClient()

    // Create  practice -----
    await API.post("api/practices/join/", {
      code: clinicCode,
      nickname: "Therapist",
      profession,
    })
  } catch (error: any) {
    const errorMessage = createErrorFromResponse(error)
    if (error) throw new Error(errorMessage)
  }
}

export const getPractice = async () => {
  const API = createJoonAPIClient()
  return API.get<{ results: Practice[] }>("api/practices").then(
    (res) => res.data
  )
}

export const getPatientGroups = async (admin_view: boolean = false) => {
  const API = createJoonAPIClient()
  return API.get<{ results: PatientGroup[] }>("api/patient-groups", {
    params: { admin_view },
  }).then((res) => res.data.results)
}

export const resendEmail = async (documentId: string) => {
  const API = createJoonAPIClient()
  try {
    await API.post(`/api/contracts/${documentId}/request-signature/`)
  } catch (error) {
    console.error("Error resending email", error)
  }
}

export const saveProfileInfo = ({
  userId,
  practiceId,
  profileInfo,
}: {
  userId: number
  practiceId: number
  profileInfo: UserProfileSettingsInfo
}) => {
  const { name, last_name, nickname } = profileInfo
  const API = createJoonAPIClient()
  trackAnalyticEvent(ANALYTIC_EVENTS.settings_save_personal)
  return Promise.all([
    API.patch(`api/users/${userId}/`, { name, last_name }),
    API.post(`api/users/${userId}/update-profile/`, {
      nickname,
      role: UserRole.THERAPIST,
    }),
    API.post(`api/practices/${practiceId}/update-member/`, {
      user_id: userId,
      nickname,
    }),
  ])
}

export const saveClinicName = ({
  practiceId,
  name,
}: {
  practiceId: number
  name: string
}) => {
  const API = createJoonAPIClient()
  trackAnalyticEvent(ANALYTIC_EVENTS.settings_save_clinic)
  return API.patch(`api/practices/${practiceId}/`, { name: name })
}

export const changeUserRole = ({
  practiceId,
  userId,
  newRole,
}: {
  practiceId: number
  userId: number
  newRole: UserRole.MEMBER | UserRole.ADMIN
}) => {
  const API = createJoonAPIClient()
  return API.post(`api/practices/${practiceId}/change-role/`, {
    user_id: userId,
    new_role: newRole,
  })
}

export const removeMember = ({
  practiceId,
  userId,
}: {
  practiceId: number
  userId: number
}) => {
  const API = createJoonAPIClient()
  trackAnalyticEvent(ANALYTIC_EVENTS.settings_remove_clinic_member)
  return API.post(`api/practices/${practiceId}/remove-member/`, {
    user_id: userId,
  })
}
export const getAuthCode = async (type: AuthCodeType) => {
  const API = createJoonAPIClient()
  return API.get("api/auth-codes/", {
    params: { type },
  })
}

const addTherapistToPatientGroup = async (
  patientGroupId: number,
  therapistId: number
) => {
  const API = createJoonAPIClient()
  return API.post(`/api/patient-groups/${patientGroupId}/add-therapist/`, {
    user_id: therapistId,
  })
}

const removeTherapistFromPatientGroup = async (
  patientGroupId: number,
  therapistId: number
) => {
  const API = createJoonAPIClient()
  return API.post(`/api/patient-groups/${patientGroupId}/remove-therapist/`, {
    user_id: therapistId,
  })
}

export const updateClinicians = async ({
  selectedClinicians,
  initialClinicians,
  patientGroupId,
}: {
  selectedClinicians: number[]
  initialClinicians: number[]
  patientGroupId: number
}) => {
  const newClinicians = selectedClinicians.filter(
    (id) => !initialClinicians.includes(id)
  )
  const newClinicianPromises = newClinicians.map((therapistId) =>
    addTherapistToPatientGroup(patientGroupId, therapistId)
  )

  const removedClinicians = initialClinicians.filter(
    (id) => !selectedClinicians.includes(id)
  )
  const removedClinicianPromises = removedClinicians.map((therapistId) =>
    removeTherapistFromPatientGroup(patientGroupId, therapistId)
  )
  return await Promise.all([
    ...newClinicianPromises,
    ...removedClinicianPromises,
  ])
}

export const requestSubscriptionLimitIncrease = async ({
  practiceId,
  requests,
}: {
  practiceId: number
  requests: {
    type: SubscriptionLimitType
    requestedValue: number
  }[]
}) => {
  const API = createJoonAPIClient()
  return API.post(`api/practices/${practiceId}/request-limit-increase/`, {
    requests: requests.map((request) => ({
      type: request.type,
      requested_value: request.requestedValue,
    })),
  })
}
