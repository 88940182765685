import { Button, JoonUIColor, Typography } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useId } from "react"
import { useParams } from "react-router-dom"

import CustomTooltip from "../../../components/tooltip/CustomTooltip"
import { getExportLink } from "../../../networking/incidents"

const ExportButton = () => {
  const { userId } = useParams()
  const tooltipId = useId()
  const isMobile = useMediaQuery("(max-width: 800px)")

  const onClick = async () => {
    const response = await getExportLink({ userId: Number(userId) })
    window.open(response.file_url)
  }

  const isExamplePatient = Number(userId) === 0

  return (
    <>
      <div data-tooltip-id={tooltipId}>
        <Button
          buttonType="secondary"
          text="Export"
          onClick={onClick}
          disabled={isExamplePatient}
          size={isMobile ? "small" : "medium"}
        />
      </div>
      {isExamplePatient && (
        <CustomTooltip id={tooltipId}>
          <Typography variant="bodySmall" color={JoonUIColor.text.inverted}>
            Data export is enabled on linked clients
          </Typography>
        </CustomTooltip>
      )}
    </>
  )
}

export default ExportButton
