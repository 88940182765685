import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Shadow,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import ModuleAssignmentDetailsModal from "./ModuleAssignmentDetailsModal"
import ModulesDndList from "./ModulesDndList"
import PageContentWrapper from "../../../../components/layout/PageContentWrapper"
import { useCurrentTrainingQuery } from "../../../../networking/queries"
import { ParentTrainingTab, PatientInfoTab } from "../../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import AddTrainingModuleModal from "../../../resources/AddTrainingModuleModal"
import useAddModuleStore from "../../../resources/useAddModuleStore"

const ModulesTab = () => {
  const { data: currentTraining } = useCurrentTrainingQuery()
  const { onOpen: openAddTrainingModuleModal } = useAddModuleStore()

  const moduleAssignments = currentTraining?.assignments || []

  return (
    <PageContentWrapper scrollable={false}>
      {moduleAssignments && moduleAssignments.length > 0 ? (
        <FlexBox direction="column" wrap={false} gap={SPACING.space2}>
          <FlexBox
            direction="row"
            justify="space-between"
            wrap={false}
            align="center"
          >
            <Typography variant="bodyBold">
              {moduleAssignments.length} active module
              {moduleAssignments?.length > 1 ? "s" : ""}
            </Typography>
            <TextButton
              style={{ marginLeft: "auto", whiteSpace: "nowrap" }}
              onClick={() => {
                openAddTrainingModuleModal()
                trackAnalyticEvent(ANALYTIC_EVENTS.open_add_modules)
              }}
            >
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.primaryAccent}
              >
                + Add more
              </Typography>
            </TextButton>
          </FlexBox>
          <ModulesDndList />
        </FlexBox>
      ) : (
        <AddModuleButton />
      )}
      <AddTrainingModuleModal />
      <ModuleAssignmentDetailsModal />
    </PageContentWrapper>
  )
}

export default ModulesTab

export const AddModuleButton = () => {
  const { onOpen: openAddTrainingModuleModal } = useAddModuleStore()
  const [isHovered, setIsHovered] = useState(false)

  const navigate = useNavigate()
  const { userId, trainingId } = useParams()

  const onClickAdd = () => {
    navigate(
      `/patients/${userId}/${PatientInfoTab.PARENT_TRAINING}/${trainingId}/${ParentTrainingTab.MODULES}`
    )
    openAddTrainingModuleModal()
    trackAnalyticEvent(ANALYTIC_EVENTS.open_add_modules)
  }

  return (
    <button style={{ padding: 0, width: "100%" }} onClick={onClickAdd}>
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        style={{
          padding: SPACING.space4,
          border: `1px solid ${JoonUIColor.border.default}`,
          borderRadius: SPACING.space2,
          boxShadow: Shadow.high,
          background: JoonUIColor.background.primaryNeutral,
          outline: isHovered
            ? `2px solid ${JoonColorExpanded.indigo300}`
            : "none",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <FlexBox align="center" gap={SPACING.space2} wrap={false}>
          <Typography variant="bodyBold" color={JoonUIColor.semantic.primary}>
            +
          </Typography>
          <Typography variant="bodyBold" textAlign="left">
            Add interactive learning module
          </Typography>
        </FlexBox>
        <Typography variant="bodySmall" textAlign="left">
          <Typography variant="caption">Best for: </Typography>
          Introduction to concepts. You can add various types of resources from
          our library filled with PDFs, reading materials, and videos.
        </Typography>
      </FlexBox>
    </button>
  )
}
