import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

import NotificationDot from "../../../components/notificationDot/NotificationDot"
import { useIncidentsQuery } from "../../../networking/queries"

const NewIncidentNotification = ({ id }: { id: number }) => {
  const { data: incidents } = useIncidentsQuery()
  const filteredIncidents = incidents?.filter(
    (incident) =>
      incident.target_behavior?.id === id ||
      incident.training_data_collection_task?.id === id
  )
  const numNewIncidents = filteredIncidents?.filter(
    (incident) => !incident.date_read
  ).length

  if (!incidents || numNewIncidents === 0) return null

  return (
    <FlexBox
      direction="row"
      align="center"
      gap={SPACING.space2}
      style={{ width: "fit-content" }}
      wrap={false}
    >
      <NotificationDot />
      <Typography
        variant="bodySmall"
        textAlign="left"
        style={{ whiteSpace: "nowrap" }}
        color={JoonUIColor.semantic.destructive}
      >
        {numNewIncidents} new {numNewIncidents === 1 ? "incident" : "incidents"}
      </Typography>
    </FlexBox>
  )
}

export default NewIncidentNotification
