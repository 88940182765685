import { FlexBox } from "@joonapp/web-shared"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

import AddQuestModal from "./AddQuestModal"
import AddEditTargetBehaviorSidePanel from "./behaviorsIncidents/AddTargetBehaviorModal"
import PatientInfoContent from "./PatientInfoContent"
import PatientInfoSidebar from "./PatientInfoSidebar"
import { useExampleBackstoryModal } from "../../components/modals/ExampleBackstoryModal"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { useLast7DaysParentTaskInstancesQuery } from "../../networking/queries"
import { requireAuth } from "../../util/auth"
import { localStorageItems } from "../../util/storage"

const PatientInfo = requireAuth(() => {
  const { userId } = useParams()
  const { onOpen: openExampleBackstoryModal } = useExampleBackstoryModal()

  // Calling these here to early cache but will need to move to appropriate
  // spots to use for the unread reflections dots
  useLast7DaysParentTaskInstancesQuery()

  useEffect(() => {
    if (
      !!localStorage.getItem(localStorageItems.needsToViewDummyData) &&
      Number(userId) === 0
    )
      openExampleBackstoryModal()
  }, [openExampleBackstoryModal, userId])

  return (
    <PageWrapper>
      <FlexBox direction="row" wrap={false}>
        <PatientInfoSidebar />
        <PatientInfoContent />
        <AddQuestModal />
        <AddEditTargetBehaviorSidePanel />
      </FlexBox>
    </PageWrapper>
  )
})

export default PatientInfo
