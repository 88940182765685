import {
  AuthCodeType,
  FamilyParentProfile,
  UserRole,
} from "@joonapp/web-shared"
import { useQuery } from "@tanstack/react-query"
import dayjs from "dayjs"
import { useParams } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { useShallow } from "zustand/react/shallow"

import { loadUser } from "./authentication"
import { getTargetBehaviors } from "./behaviors"
import { loadFamily } from "./families"
import { getIncidents } from "./incidents"
import { getReceivedInvites, getSentInvites } from "./invitations"
import { getLearningModules } from "./learningModules"
import {
  getParentTraining,
  getParentTrainings,
  getTrainingMasteryProgress,
} from "./parentTraining"
import {
  getParentTaskInstances,
  getQuestById,
  getQuestLog,
  getRoutineStats,
} from "./quests"
import { getTagsList } from "./resources"
import { getAuthCode, getPatientGroups, getPractice } from "./user"
import { DATE_RANGES } from "../constants"
import {
  DataFormat,
  queryDataResponse,
} from "../pages/patientInfo/homeRoutines/overTimeProgress/data"
import { useOverTimeProgressStore } from "../pages/patientInfo/homeRoutines/overTimeProgress/useOverTimeProgressStore"
import useParentTrainingStore from "../pages/patientInfo/parentTraining/useParentTrainingStore"
import {
  exampleIncidents,
  exampleParentTasks,
  exampleParentTrainings,
  examplePatientFamily,
  exampleTargetBehaviors,
  getExampleRoutineStats,
} from "../pages/patientsDashboard/exampleChild"
import useResourcesFilterStore from "../pages/resources/useResourceFilterStore"
import { DateRange } from "../types"
import { getHighlightsFromData } from "../util/quests"
import { sessionManager } from "../util/storage"
import {
  createExampleMasteryProgressData,
  getPatientGroupByUserId,
  groupInvitesByInvitee,
  groupInvitesByInviter,
} from "../util/util"

export const QUERY_KEYS = {
  PATIENT_GROUPS: "patient-groups",
  USER: "user",
  PRACTICE: "practice",
  CHILD_QUEST_DATA: "child-quest-data",
  QUEST_BOARD: "quest-board",
  EXPERIMENTS: "experiments",
  QUEST_TEMPLATES: "quest-templates",
  QUEST_ROUTINE_STATS: "quest-routine-stats",
  ONE_OFF_QUEST_DATA: "one-off-quest-data",
  SINGLE_QUEST: "single-quest",
  QUEST_LOG: "quest-log",
  FAMILY: "family",
  PARENT_TASK_INSTANCES: "parent-task-instances",
  PARENT_TRAINING_DATA_TASKS: "parent-training-data-tasks",
  RESOURCES: "resources",
  RESOURCE_TAGS: "resource-tags",
  ADMIT_PATIENT_AUTH_CODE: "admit-patient-auth-code",
  TARGET_BEHAVIORS: "target-behaviors",
  INCIDENTS: "incidents",
  SENT_INVITES: "sent-invites",
  RECEIVED_INVITES: "parent-invites",
  PARENT_TRAININGS: "parent-trainings",
  PARENT_TRAINING: "parent-training",
  LEARNING_MODULES: "learning-modules",
  LEARNING_MODULE_COMPLETIONS: "learning-module-completions",
  TRAINING_MASTERY_PROGRESS: "training-mastery-progress",
}

export const usePatientGroupsQuery = (params?: { admin_view: boolean }) => {
  return useQuery({
    queryKey: [QUERY_KEYS.PATIENT_GROUPS, params?.admin_view],
    queryFn: () => getPatientGroups(params?.admin_view),
  })
}

export const useCurrentPatientQuery = () => {
  const patientGroupsQuery = usePatientGroupsQuery()
  const { userId } = useParams()
  const patient = getPatientGroupByUserId(
    patientGroupsQuery.data,
    Number(userId)
  )
  const patientUser = patient?.profiles.find(
    (profile: any) => profile.user.id === Number(userId)
  )

  return {
    ...patientGroupsQuery,
    data: patientUser || undefined,
  }
}

export const useUserQuery = () => {
  const hasRefreshToken = sessionManager.getRefreshToken()

  const { data: user, status: userStatus } = useQuery({
    queryKey: [QUERY_KEYS.USER],
    queryFn: loadUser,
    enabled: !!hasRefreshToken,
    select: (response) => response.data,
    onError: sessionManager.clearAuthTokens,
  })

  return { user, userStatus }
}

export const usePracticeQuery = () => {
  const hasRefreshToken = sessionManager.getRefreshToken()

  const { data: practice, status: practiceStatus } = useQuery({
    queryKey: [QUERY_KEYS.PRACTICE],
    queryFn: getPractice,
    enabled: !!hasRefreshToken,
    select: (data) => data.results?.[0],
    retry: 3,
  })

  return { practice, practiceStatus }
}

export const useChildQuestDataQuery = (
  childId: string | undefined,
  period: "week" | "month" | "day",
  selectedQuestIds: number[],
  dateRange: DateRange
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CHILD_QUEST_DATA, childId, dateRange],
    queryFn: () => {
      // If the childId query param is "0", return the example data
      if (Number(childId) === 0) return getExampleRoutineStats({ dateRange })
      else return getRoutineStats({ childId, dateRange })
    },
    select: (data) => {
      return queryDataResponse(data.data, selectedQuestIds, period)
    },
    enabled: !!childId || Number(childId) === 0,
  })
}

export const useOverTimeProgressQuery = () => {
  const { userId } = useParams()
  const { dateRange, period, selectedQuests } = useOverTimeProgressStore()
  return useChildQuestDataQuery(
    userId,
    period.value as "day" | "week" | "month",
    selectedQuests.map((quest) => quest.id),
    dateRange
  )
}

export const usePatientWeeklyProgressQuery = () => {
  const { userId: childId } = useParams()
  const dateRange = DATE_RANGES.past14Days

  return useQuery({
    queryKey: [QUERY_KEYS.CHILD_QUEST_DATA, childId, dateRange],
    queryFn: () => {
      // If the childId query param is "0", return the example data
      if (Number(childId) === 0) return getExampleRoutineStats({ dateRange })
      else return getRoutineStats({ childId, dateRange })
    },
    select: (data: { data: DataFormat }) => getHighlightsFromData(data.data),
    enabled: !!childId || Number(childId) === 0,
  })
}

export const useReceivedInvitesQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.RECEIVED_INVITES],
    queryFn: getReceivedInvites,
    select: (response) => groupInvitesByInviter(response.data.results),
    enabled: sessionManager.hasRefreshToken(),
  })
}

export const useSentInvitesQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.SENT_INVITES],
    queryFn: getSentInvites,
    select: (response) => groupInvitesByInvitee(response.data.results),
    enabled: sessionManager.hasRefreshToken(),
  })
}

export const useQuestQuery = (id: number | null) => {
  return useQuery({
    queryKey: [QUERY_KEYS.SINGLE_QUEST, id],
    queryFn: () => getQuestById(id as number),
    select: (res) => {
      return res.data
    },
    enabled: !!id,
  })
}

export const useQuestLogQuery = (seriesId: number) => {
  return useQuery({
    queryKey: [QUERY_KEYS.QUEST_LOG, seriesId],
    queryFn: () => getQuestLog({ seriesId }),
    enabled: !!seriesId,
  })
}

// Get current patient's family
export const useFamilyQuery = (id?: number) => {
  const { data: currentChild } = useCurrentPatientQuery()
  // @ts-ignore - family_id was added to this response
  const familyId = id ?? currentChild?.user?.family_id
  const { userId } = useParams()
  const isExamplePatient = Number(userId) === 0

  return useQuery({
    queryKey: [QUERY_KEYS.FAMILY, familyId],
    queryFn: () =>
      isExamplePatient ? { data: examplePatientFamily } : loadFamily(familyId),
    select: (response) => response.data,
    enabled: isExamplePatient || !!familyId,
  })
}

// Shortcut for query to get all parents of current selected patient
export const usePatientParentsQuery = (id?: number) => {
  const queryResponse = useFamilyQuery(id)

  const parents = queryResponse?.data?.profiles
    .filter((profile) => profile.role === UserRole.PARENT)
    ?.sort((a, b) => a.user.name.localeCompare(b.user.name)) as
    | FamilyParentProfile[]
    | undefined

  return {
    ...queryResponse,
    data: parents,
  }
}

export const useParentTaskInstancesQuery = ({
  userIds,
  seriesId,
  minDate,
  maxDate,
  status,
  enabled = true,
}: {
  userIds: number[]
  seriesId?: number
  minDate?: string
  maxDate?: string
  status?: string
  enabled?: boolean
}) => {
  const { userId } = useParams()
  const isExamplePatient = Number(userId) === 0

  return useQuery({
    queryKey: [QUERY_KEYS.PARENT_TASK_INSTANCES, seriesId, userIds, status],
    queryFn: () =>
      isExamplePatient
        ? exampleParentTasks
        : getParentTaskInstances({
            userIds,
            seriesId,
            minDate,
            maxDate,
            status,
          }),
    select: (data) => data.flat(),
    enabled: enabled && userIds?.length > 0,
  })
}

export const useLast7DaysParentTaskInstancesQuery = ({
  enabled = true,
}: {
  enabled?: boolean
} = {}) => {
  const { data: parents } = usePatientParentsQuery()
  const userIds = parents?.map((parent) => parent.user.id) || []
  const maxDate = dayjs().format("YYYY-MM-DD")
  const minDate = dayjs().subtract(7, "days").format("YYYY-MM-DD")
  return useParentTaskInstancesQuery({ userIds, minDate, maxDate, enabled })
}

export const useLearningModulesQuery = () => {
  const {
    displayFavorites,
    displayMadeByYourClinic,
    selectedTags,
    selectedModuleTypes,
    searchValue,
  } = useResourcesFilterStore(
    useShallow((state) => ({
      displayFavorites: state.displayFavorites,
      displayMadeByYourClinic: state.displayMadeByYourClinic,
      selectedTags: state.selectedTags,
      selectedModuleTypes: state.selectedModuleTypes,
      searchValue: state.searchValue,
    }))
  )

  const [debouncedSearchValue] = useDebounce(searchValue, 300) // Adjust the debounce delay as needed

  return useQuery({
    queryKey: [
      QUERY_KEYS.LEARNING_MODULES,
      debouncedSearchValue,
      displayFavorites,
      selectedTags,
      selectedModuleTypes,
      displayMadeByYourClinic,
    ],
    queryFn: () =>
      getLearningModules({
        title: debouncedSearchValue,
        favorited: displayFavorites,
        types: selectedModuleTypes,
        tag_ids: selectedTags,
        custom: displayMadeByYourClinic,
      }),
  })
}

export const useResourceTagsQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.RESOURCE_TAGS],
    queryFn: getTagsList,
    select: (tags) => tags?.sort((a, b) => a.title.localeCompare(b.title)),
  })
}

export const useAuthCodeQuery = (type: AuthCodeType) => {
  const { practice } = usePracticeQuery()
  return useQuery({
    queryKey: [QUERY_KEYS.ADMIT_PATIENT_AUTH_CODE, type],
    queryFn: () => {
      return getAuthCode(type)
    },
    select: (response) => response.data.results[0],
    enabled: !!practice,
  })
}

export const useTargetBehaviorsQuery = () => {
  const { userId } = useParams()
  return useQuery({
    queryKey: [QUERY_KEYS.TARGET_BEHAVIORS, userId],
    queryFn: () =>
      Number(userId) === 0
        ? exampleTargetBehaviors
        : getTargetBehaviors({ userId: Number(userId) }),
  })
}

export const useIncidentsQuery = (targetBehaviorId?: number) => {
  const { userId } = useParams()

  return useQuery({
    queryKey: [QUERY_KEYS.INCIDENTS, userId, targetBehaviorId],
    queryFn: () =>
      Number(userId) === 0
        ? exampleIncidents.filter(
            (incident) =>
              !targetBehaviorId ||
              incident.target_behavior?.id === targetBehaviorId
          )
        : getIncidents({ userId: Number(userId), targetBehaviorId }),
  })
}

export const useParentTrainingsQuery = () => {
  const { userId } = useParams()
  const { selectedParentId } = useParentTrainingStore()
  const isExamplePatient = Number(userId) === 0
  return useQuery({
    queryFn: () =>
      isExamplePatient
        ? exampleParentTrainings
        : getParentTrainings(selectedParentId ?? undefined),
    queryKey: [QUERY_KEYS.PARENT_TRAININGS, userId, selectedParentId],
    enabled: !!selectedParentId,
  })
}

export const useCurrentTrainingQuery = () => {
  const { trainingId } = useParams()
  return useSingleTrainingQuery(Number(trainingId))
}

export const useSingleTrainingQuery = (id: number) => {
  const { userId } = useParams()
  const isExamplePatient = Number(userId) === 0
  const { data: parentTrainings } = useParentTrainingsQuery()

  return useQuery({
    queryKey: [QUERY_KEYS.PARENT_TRAINING, id],
    queryFn: () =>
      isExamplePatient
        ? exampleParentTrainings[0]
        : getParentTraining(id ?? Number(id)),
    enabled: !!id,
    // If the parent trainings query is already loaded, use the cached data
    initialData: parentTrainings?.find((training) => training.id === id),
  })
}

export const useTrainingMasteryProgressQuery = (data?: {
  training_id?: number
  min_date?: string
  max_date?: string
}) => {
  const { training_id, min_date, max_date } = data || {}
  const { data: currentTraining } = useCurrentTrainingQuery()
  const { userId } = useParams()

  const isExamplePatient = Number(userId) === 0

  return useQuery({
    queryKey: [
      QUERY_KEYS.TRAINING_MASTERY_PROGRESS,
      training_id || currentTraining?.id,
    ],
    queryFn: () =>
      isExamplePatient
        ? createExampleMasteryProgressData()
        : getTrainingMasteryProgress({
            training_id: training_id || currentTraining?.id,
            min_date,
            max_date,
          }),
  })
}

export const useModulesToReviewQuery = (completionId?: number) => {
  const currentTrainingQuery = useCurrentTrainingQuery()

  return {
    ...currentTrainingQuery,
    data:
      currentTrainingQuery?.data?.assignments.filter(
        (assignment) =>
          !assignment.assignment_completion.date_reviewed &&
          !!assignment.assignment_completion.date_completed &&
          (!completionId ||
            assignment.assignment_completion.id === completionId)
      ).length ?? 0,
  }
}
