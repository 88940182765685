import BackButton from "../../components/buttons/BackButton"
import { requireAuth } from "../../util/auth"

const HowJoonWorks = requireAuth(() => {
  return (
    <div className="page-wrapper">
      <BackButton />
      <div className="page-title">How Joon Works</div>
      <div className="help-section">
        <div className="help-card">
          <div className="help-card-title">Introductory Video to Joon</div>
          <div>
            Watch a short 30 second video to quickly learn what Joon is and how
            it works
          </div>

          <div className="playerWrapper">
            <iframe
              src="https://player.vimeo.com/video/806932953?h=d7a0084eba&title=0&byline=0&portrait=0"
              width="100%"
              height="100%"
              title="test"
              allowFullScreen
              allow="autoplay; fullscreen;"
              className="reactPlayer"
            ></iframe>
          </div>
        </div>
        <div className="help-card">
          <div className="help-card-title">Demo of the Joon Parent App</div>
          <div>
            Watch an overview of how to use the Joon Parent App to create a
            routine
          </div>

          <div className="playerWrapper">
            <iframe
              src="https://player.vimeo.com/video/861720125?h=b743699489&title=0&byline=0&portrait=0"
              width="100%"
              height="100%"
              title="test"
              allowFullScreen
              allow="autoplay; fullscreen;"
              className="reactPlayer"
            ></iframe>
          </div>
        </div>
        <div className="help-card">
          <div className="help-card-title">Demo of the Joon Game</div>
          <div>
            Watch an overview of how children play the Joon Game to improve
            their executive functioning
          </div>

          <div className="playerWrapper">
            <iframe
              src="https://player.vimeo.com/video/861720681?h=a0666c1f10&title=0&byline=0&portrait=0"
              width="100%"
              height="100%"
              title="test"
              allowFullScreen
              allow="autoplay; fullscreen;"
              className="reactPlayer"
            ></iframe>
          </div>
        </div>
        <div className="help-card">
          <div className="help-card-title">Demo of the Clinician Dashboard</div>
          <div>
            Watch an overview of how to use the Clinician Dashboard to view your
            client’s progress and data
          </div>
          <div className="playerWrapper">
            <iframe
              src="https://player.vimeo.com/video/861721149?h=39fccce1cf&title=0&byline=0&portrait=0"
              width="100%"
              height="100%"
              title="test"
              allow="autoplay; fullscreen;"
              allowFullScreen
              className="reactPlayer"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  )
})

export default HowJoonWorks
