import {
  Button,
  Checkbox,
  CalloutBox,
  Typography,
  SPACING,
  FlexBox,
  JoonUIColor,
  Modal,
  TextInput,
  ModalHeader,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import { useEffect, useMemo, useState } from "react"
import { create } from "zustand"

import { acceptReceivedInvite } from "../../networking/invitations"
import {
  QUERY_KEYS,
  usePatientGroupsQuery,
  usePracticeQuery,
  useUserQuery,
} from "../../networking/queries"
import { saveProfileInfo } from "../../networking/user"
import { InvitationGroup, SubscriptionLimitType } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import PatientPaywallReminder from "../paywall/PatientPaywallReminder"
import usePaywall from "../paywall/usePaywall"

interface InvitationAcceptModalStore {
  isOpen: boolean
  onOpen: (invitationGroup: InvitationGroup) => void
  onClose: () => void
  invitationGroup: InvitationGroup | null
}

export const useInvitationAcceptModalStore = create<InvitationAcceptModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (invitationGroup: InvitationGroup) =>
      set({ isOpen: true, invitationGroup }),
    onClose: () => set({ isOpen: false, invitationGroup: null }),
    invitationGroup: null,
  })
)

export const InvitationAcceptModal = () => {
  const { isOpen, onClose, invitationGroup } = useInvitationAcceptModalStore()
  const [selectedChildren, setSelectedChildren] = useState<number[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [nickname, setNickname] = useState("")
  const { data: patientGroups } = usePatientGroupsQuery()
  const { practice } = usePracticeQuery()
  const { user } = useUserQuery()
  const { remainingSlots: remainingPatientSlots } = usePaywall(
    SubscriptionLimitType.NUM_LINKED_PATIENTS
  )

  const isFirstLink = patientGroups?.length === 0

  const subjectData = useMemo(
    () => invitationGroup?.flatMap((invitation) => invitation.subject_user),
    [invitationGroup]
  )

  const queryClient = useQueryClient()

  const selectChild = (id: number) => {
    if (selectedChildren.includes(id))
      setSelectedChildren(selectedChildren.filter((childId) => childId !== id))
    else {
      const newChildren = [...selectedChildren, id]
      if (remainingPatientSlots <= selectedChildren.length) {
        newChildren.shift()
      }
      setSelectedChildren(newChildren)
    }
  }

  useEffect(() => {
    // auto select all children
    if (!subjectData) return
    setSelectedChildren(
      subjectData?.map((child) => child.id).slice(remainingPatientSlots)
    )
  }, [subjectData, remainingPatientSlots])

  const acceptInvitation = async () => {
    if (!invitationGroup || !practice || !user) return

    const selectedInvitationsToAccept: number[] =
      invitationGroup
        ?.filter((invitation) =>
          selectedChildren.includes(invitation.subject_user.id)
        )
        .map((invitation) => invitation.id) ?? []
    await acceptReceivedInvite(selectedInvitationsToAccept)
    if (isFirstLink) {
      saveProfileInfo({
        userId: user.id,
        practiceId: practice?.id,
        profileInfo: {
          name: user.name || "",
          last_name: user.last_name || "",
          nickname,
        },
      })
    }
    setIsLoading(false)
    onClose()
    queryClient.invalidateQueries([QUERY_KEYS.RECEIVED_INVITES])
    queryClient.invalidateQueries([QUERY_KEYS.PATIENT_GROUPS])
    queryClient.invalidateQueries([QUERY_KEYS.PRACTICE]) // To update the remaining patient slots
    trackAnalyticEvent(ANALYTIC_EVENTS.accept_parent_invite)
  }

  if (!invitationGroup || invitationGroup.length === 0) return null
  const inviter = invitationGroup[0].inviter

  return (
    <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon={false}>
      <ModalHeader
        title="Parent Invitation"
        onClose={onClose}
        showBorderBottom
      />

      <FlexBox
        direction="column"
        wrap={false}
        gap={SPACING.space4}
        style={{
          width: "min(500px, 95vw)",
          padding: `${SPACING.space4} ${SPACING.space6} ${SPACING.space6} ${SPACING.space6}`,
          overflowY: "auto",
        }}
      >
        <PatientPaywallReminder
          additionalText="Upgrade now to accept client invites."
          buttonType="primary"
        />
        <Typography variant="bodyBold" textAlign="left">
          {inviter.name} ({inviter.email}) has invited you to link with their
          {invitationGroup?.length > 1
            ? " childrens' accounts"
            : " child's account"}
          .
        </Typography>
        <FlexBox gap={SPACING.space1} direction="column">
          {invitationGroup.length > 1 && (
            <Typography variant="body" textAlign="left">
              Select the children you would like to link with
            </Typography>
          )}
          <FlexBox gap={SPACING.space2} direction="row">
            {subjectData?.map((child, i) => (
              <Checkbox
                name="test"
                key={i}
                label={child.name}
                onChange={() => selectChild(child.id)}
                selected={selectedChildren.includes(child.id)}
              />
            ))}
          </FlexBox>
        </FlexBox>
        {isFirstLink && (
          <FlexBox gap={SPACING.space1} direction="column">
            <Typography variant="body">
              How do your clients refer to you?
            </Typography>
            <TextInput
              name="Nickname"
              value={nickname}
              fullWidth
              placeholder="i.e. Dr. Goldblum"
              onChange={(e) => setNickname(e.target.value)}
              maxLength={30}
            />
          </FlexBox>
        )}
        <div style={{ margin: `${SPACING.space2} 0`, width: "100%" }}>
          <CalloutBox fullWidth>
            <div className="font-footnote">
              Once connnected, you will gain access to the following data:
            </div>
            <div className="font-footnote">
              <ul>
                <li>Child data (names & birthdates)</li>
                <li>Quest & questionnaire response data</li>
                <li>Child progress data</li>
              </ul>
            </div>
          </CalloutBox>
        </div>
      </FlexBox>
      <FlexBox
        justify="flex-end"
        align="center"
        gap={SPACING.space4}
        wrap={false}
        style={{
          position: "sticky",
          bottom: 0,
          padding: `${SPACING.space4} ${SPACING.space6}`,
          background: JoonUIColor.background.primaryNeutral,
        }}
      >
        <button style={{ padding: SPACING.space4 }} onClick={onClose}>
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            Maybe later
          </Typography>
        </button>
        <Button
          text="Accept Invitation"
          isLoading={isLoading}
          onClick={acceptInvitation}
          disabled={
            selectedChildren.length === 0 ||
            (isFirstLink && !nickname) ||
            selectedChildren.length > remainingPatientSlots
          }
        />
      </FlexBox>
    </Modal>
  )
}
