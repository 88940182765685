import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Shadow,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import AddEditTodoModal from "./AddEditTodoModal"
import ParentTaskCard from "./ParentTaskCard"
import ParentTaskDetailsModal from "./ParentTaskDetailsModal"
import PageContentWrapper from "../../../../components/layout/PageContentWrapper"
import { SkeletonLoaderRow } from "../../../../components/loading/SkeletonLoader"
import PageWrapper from "../../../../components/pageWrapper/PageWrapper"
import { useCurrentTrainingQuery } from "../../../../networking/queries"
import { ParentTrainingTab, PatientInfoTab } from "../../../../types"
import { useAddEditParentTaskStore } from "../../useAddEditParentTaskStore"

const TodoTasksTab = () => {
  const { data: currentTraining, isLoading: isLoadingParentTraining } =
    useCurrentTrainingQuery()
  const { onOpen: onOpenAddParentTask } = useAddEditParentTaskStore()

  if (!currentTraining) return <></>

  const parentTasks = currentTraining?.quests || []

  return (
    <PageWrapper>
      <PageContentWrapper>
        {isLoadingParentTraining ? (
          <SkeletonLoaderRow numRows={3} />
        ) : parentTasks && parentTasks.length > 0 ? (
          <FlexBox direction="column" wrap={false} gap={SPACING.space2}>
            <FlexBox
              direction="row"
              justify="space-between"
              wrap={false}
              align="center"
            >
              <Typography variant="bodyBold">
                {parentTasks.length} quick to-dos
                {parentTasks?.length > 1 ? "s" : ""}
              </Typography>
              <TextButton
                style={{ marginLeft: "auto", whiteSpace: "nowrap" }}
                onClick={() => onOpenAddParentTask()}
              >
                <Typography
                  variant="bodyBold"
                  color={JoonUIColor.text.primaryAccent}
                >
                  + Add task
                </Typography>
              </TextButton>
            </FlexBox>
            <FlexBox direction="column" gap={SPACING.space2}>
              {parentTasks?.map((task) => (
                <ParentTaskCard key={task.id} task={task} />
              ))}
            </FlexBox>
          </FlexBox>
        ) : (
          <TodoTasksEmptyState />
        )}
      </PageContentWrapper>
      <AddEditTodoModal />
      <ParentTaskDetailsModal />
    </PageWrapper>
  )
}

export default TodoTasksTab

export const TodoTasksEmptyState = () => {
  const { onOpen: onOpenAddParentTask } = useAddEditParentTaskStore()
  const [isHovered, setIsHovered] = useState(false)

  const navigate = useNavigate()
  const { userId, trainingId } = useParams()

  const onClickAdd = () => {
    navigate(
      `/patients/${userId}/${PatientInfoTab.PARENT_TRAINING}/${trainingId}/${ParentTrainingTab.TODO_TASKS}`
    )
    onOpenAddParentTask()
  }

  return (
    <button style={{ padding: 0, width: "100%" }} onClick={onClickAdd}>
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        style={{
          padding: SPACING.space4,
          border: `1px solid ${JoonUIColor.border.default}`,
          borderRadius: SPACING.space2,
          background: JoonUIColor.background.primaryNeutral,
          boxShadow: Shadow.high,
          outline: isHovered
            ? `2px solid ${JoonColorExpanded.indigo300}`
            : "none",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <FlexBox align="center" gap={SPACING.space2} wrap={false}>
          <Typography variant="bodyBold" color={JoonUIColor.semantic.primary}>
            +
          </Typography>
          <Typography variant="bodyBold" textAlign="left">
            Add practice tasks
          </Typography>
        </FlexBox>
        <Typography variant="bodySmall" textAlign="left">
          <Typography variant="caption">Best for: </Typography>
          Practicing key learnings, building a new habit, or for anything else
          you’d like your parents to do outside of session.
        </Typography>
      </FlexBox>
    </button>
  )
}
